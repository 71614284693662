export const LANDING_PARA =
  "WhatsLegal befindet sich in der Betaphase zum Testen und ist beschränkt auf deutsche Gesetze. Mit der Nutzung der WhatsLegal App erklären Sie sich mit den AGB's https://www.whatslegal.app/agbs sowie den Nutzungsbedingungen https://www.whatslegal.app/ nutzungsbedingungen einverstanden. WhatsLegal übernimmt keine Gewähr für die Richtigkeit der Antworten. WhatsLegal ist und ersetzt keine anwaltliche Beratung.";
// export const BASE_URL = "https://chat.whatslegal.ai/";
export const BASE_URL = "https://frag-das-gesetz.de/";
export const LOGIN_ENDPOINT = "login";
export const SIGNUP_ENDPOINT = "register";

export const dataSourceMenu = ["DS 1", "DS 2", "DS 3"];

export const modelSelectorMenu = [
  "Assistent 1",
  "Assistent 2",
  "Assistent 3",
  "Assistent 4",
];


export const contactMail = "kontakt@frag-das-gesetz.de";
export const googleAnylaticsToken = "G-GJPE4KDJJH";


export const homePageWhyWhatslegalContent = (`# Warum Whatslegal?
  * **Anonym:** Bei WhatsLegal fragen Sie anonym – kein Login, keine E-Mail erforderlich.
  * **Kostenlos:** WhatsLegal ist kostenlos für 10 Fragen pro Tag (10 weitere Fragen für 2€).
  * **Vertrauensvoll:** WhatsLegal referenziert direkt auf deutsche oder EU Gesetze.
  * **Einfach:** Mit WhatsLegal kann jede*r Gesetze verstehen – egal ob 8 oder 80 Jahre.
          `);

export const newwWiteLabelContent = (`# Features
  * Erfüllung der Kundenerwartung an neueste gen. KI Technologie
  * verlängerte Verweildauer auf Ihrer webseite
  * verbessertes Google Ranking
  * direkte Verlinkung auf Ihre Angebote
  * sofort einsetzbar
  * Erweiterbar mit Ihren Inhalten
          `);