import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer-Mobile';
import Footer2 from '../components/Footer';
import useWindowSize from '../hooks/useWindowSize';
import TextComponent from '../components/Text';
import SEO from '../components/Seo';
import { seoData, tipsAndTricks } from '../data/tips-tricks';

const TipsAndTricks: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };
  return (
    <>
      <SEO
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
        url={seoData.url}
      />
      <div style={containerStyle}>
        <Header />
        <TextComponent content={tipsAndTricks} />
        {isMobile ? <Footer2Mobile /> : <Footer2 />}
      </div>
    </>
  );
};

export default TipsAndTricks;
