import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer-Mobile';
import Footer2 from '../components/Footer';
import useWindowSize from '../hooks/useWindowSize';
import TextComponent from '../components/Text';
import { imprint, seoData } from '../data/imprint';
import SEO from '../components/Seo';

const Imprint: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
        url={seoData.url}
      />
      <div >
        <Header />
        <TextComponent content={imprint} />
        {isMobile ? <Footer2Mobile /> : <Footer2 />}
      </div>
    </>
  );
};

export default Imprint;
