export const agbs = `## Geltung der AGB
Mit der Nutzung von WhatsLegal GmbH erklären sich die Kunden mit diesen AGB einverstanden. Abweichende Bedingungen der Kunden werden nicht anerkannt, es sei denn, WhatsLegal GmbH stimmt ihrer Geltung ausdrücklich schriftlich zu.

## Vertragsschluss und Vertragsinhalt
Das Vertragsverhältnis zwischen den Kunden und WhatsLegal GmbH kommt mit dem Abschluss des Registrierungsvorgangs zustande. WhatsLegal GmbH richtet sich überwiegend an Geschäftskunden und kann den Vertragsschluss aus sachlichen Gründen verweigern. Der Leistungsumfang und die Vergütung ergeben sich aus der jeweiligen Tarifbeschreibung.

## Registrierung
Bei der Registrierung erhalten Kunden ihre Anmeldeinformationen per E-Mail. Es liegt in der Verantwortung des Kunden, diese Daten sicher aufzubewahren und Missbrauch sofort zu melden. Eine gültige E-Mail-Adresse muss bereitgestellt und gepflegt werden. Unvollständige Registrierungen können innerhalb einer Woche gelöscht werden.

## Angebote Dritter
WhatsLegal GmbH ist nicht Vertragspartner für Dienste, die von Drittanbietern angeboten werden. Solche Angebote unterliegen den Vertragsbedingungen der Drittanbieter.

## Vergütung und Rechnung
Die Vergütung wird zu Beginn des gewählten Zahlungsintervalls fällig. Zusätzliche Leistungen werden nach Rechnungsstellung fällig. Änderungen der Vergütung treten erst zum Beginn des nächsten Vertragszeitraums in Kraft. Kunden müssen Kosten für Zahlungsausfälle tragen. Bei Zahlungsverzug können Verzugszinsen berechnet und der Zugang gesperrt werden.

## Pflichten der Kunden
Kunden müssen wahrheitsgemäße und rechtlich zulässige Daten angeben. Sie sind für die Sicherheit ihrer Zugangsdaten verantwortlich und müssen WhatsLegal GmbH bei Verdacht auf Missbrauch informieren.

## Nutzungsberechtigung
Die Nutzung des Dienstes ist nur für vertragsgemäße Zwecke erlaubt. Eine Überlassung an Dritte ist verboten. Übermäßige oder missbräuchliche Nutzung ist untersagt.

## Tarifänderungen und Kündigung
Verträge verlängern sich automatisch, wenn sie nicht sieben Tage vor Ablauf gekündigt werden. Eine Kündigung ist jederzeit möglich für kostenlose Tarife. Ein Upgrade auf einen höheren Tarif ist jederzeit möglich, ein Downgrade jedoch nicht. Vorzeitige Kündigungen berechtigen nicht zur Rückzahlung der Vergütung. Beide Parteien können bei Vorliegen eines wichtigen Grundes außerordentlich kündigen.

## Leistungsumfang
Der Leistungsumfang ergibt sich aus dem aktuellen Stand des Dienstes und der Leistungsbeschreibung zum Zeitpunkt des Vertragsschlusses. WhatsLegal GmbH behält sich vor, Funktionen zu ändern, um technischen Fortschritt zu ermöglichen oder Missbrauch zu verhindern.

## Leistungsänderungen und Gewährleistung
Mängel sind unverzüglich per E-Mail zu melden. WhatsLegal GmbH behebt Mängel durch Nachbesserung. Erst bei Fehlschlagen der Nachbesserung können Kunden Minderung verlangen oder vom Vertrag zurücktreten. Gewährleistungsansprüche verjähren nach einem Jahr.

## Gewährleistungsausschluss
Die Nutzung des Dienstes erfolgt auf eigenes Risiko. WhatsLegal GmbH haftet nicht für direkte, indirekte oder Folgeschäden durch die Nutzung des Dienstes.

## Verfügbarkeit
Eine 100%ige Verfügbarkeit des Dienstes kann technisch nicht garantiert werden. WhatsLegal GmbH sichert eine 98%ige Verfügbarkeit im Jahresdurchschnitt zu. Wartungsarbeiten werden rechtzeitig angekündigt.

## Schnittstellen
WhatsLegal GmbH bietet APIs für den Zugriff auf den Dienst. Für die Kompatibilität mit Drittanbieter-Software wird keine Gewähr übernommen.

## Haftung
WhatsLegal GmbH haftet für Schäden nur bei Vorsatz und grober Fahrlässigkeit. Bei einfacher Fahrlässigkeit haftet WhatsLegal GmbH nur für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und bei Verletzung wesentlicher Vertragspflichten.

## Änderung der AGB
WhatsLegal GmbH kann die AGB jederzeit ändern. Änderungen wesentlicher Regelungen werden mindestens zwei Wochen im Voraus mitgeteilt. Kunden können der Änderung widersprechen. Im Fall eines Widerspruchs kann WhatsLegal GmbH den bisherigen AGB treu bleiben oder den Vertrag kündigen.

## Vertraulichkeit und Datenschutz
Kundendaten werden vertraulich behandelt und nur für vertraglich vereinbarte Zwecke verwendet. Weitere Datenschutzbestimmungen sind den Datenschutzrichtlinien zu entnehmen.

## Form der Erklärungen
Erklärungen im Rahmen dieser AGB bedürfen mindestens der Schriftform (z.B. E-Mail). Änderungen der AGB müssen schriftlich erfolgen.

## Schlussbestimmungen
Auf diesen Vertrag findet deutsches Recht Anwendung. Gerichtsstand ist München. Sollten einzelne Bestimmungen unwirksam sein, bleibt die Gültigkeit der übrigen Bestimmungen unberührt.

---

Weitere Datenschutzbestimmungen finden Sie in unserer [Datenschutzerklärung](link-to-privacy-policy).
`



//SEO DATA

export const seoData = {
    title: "FragDasGesetz",
    description: "Nutzen Sie den kostenlosen KI Chatbot für Gesetze, der über 5.000 aktuelle deutsche Bundesgesetze und Verordnungen leicht zugänglich und verständlich macht.",
    keywords: "KI Chatbot, Gesetze, deutsche Bundesgesetze, kostenlos, verständlich, Gesetzesinformationen, anonymer Zugang, rechtliche Recherche",
    url: "https://www.whatslegal.com/",
  };