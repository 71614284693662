export const title = "Mehr Leads <br /> mit White Label";
export const subTitle = "Nutzen Sie unseren KI Chatbot mit über<br/>4.500 aktuellen Gesetzen - in Ihrem Design"
export const buttonText = "Kundenbeispiel Captain Frank"


export const secondBannerText = "Wir integrieren Ihr Angebot<br /> in der generierten KI Antwort"
export const secondBannerSubText = ""


export const newWiteLabelContentFeatures = (`# Features
  * Erfüllung der Kundenerwartung an neueste gen. KI Technologie
  * verlängerte Verweildauer auf Ihrer webseite
  * verbessertes Google Ranking
  * direkte Verlinkung auf Ihre Angebote
  * sofort einsetzbar
  * Erweiterbar mit Ihren Inhalten
        `);

export const featuresButtonText = "Kontaktieren Sie uns";

//SEO DATA

export const seoData = {
  title: "FragDasGesetz als White Label",
  description: "Nutzen Sie unseren KI Chatbot in Ihrem Design als White Label, um besseren Kundenservice anzubieten und Lead zu generieren",
  keywords: "KI Chatbot für Gesetze als White label, Gesetze, deutsche Bundesgesetze, Recht für alle",
  url: "https://www.frag-das-gesetz.de/",
  altTextBackground: "Anwalt nutzt white label von frag das Gesetz für Kundenservice und Lead Generierung",
  altTextCaptainFrankLogo: "Referenz Captain Frank",
  kiChatbotWhiteLabelEmbeddedAltText: "KI Chatbot als White Label embedded Version",
  kiChatbotWhiteLabelOverlayAltText: "KI Chatbot als white label also overlay version",
  iFrameFeaturesTitle: "Video über White Label und Kundengewinnung"
};
