import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer-Mobile';
import Footer2 from '../components/Footer';
import useWindowSize from '../hooks/useWindowSize';
import TextComponent from '../components/Text';
import TextVideo from '../components/TextVideo';
import { featuresContent, purpose, seoData } from '../data/about';
import SEO from '../components/Seo';

const About: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  return (
    <>
      <SEO
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
        url={seoData.url}
      />
      <div style={containerStyle}>
        <Header />
        <TextComponent content={purpose} />
        <TextVideo content={featuresContent} showButton={"false"} videoLink='https://share.synthesia.io/embeds/videos/30eb92cf-8033-4b5e-99b7-0349b2c60eb1' backgroundColor='#f0f0f0' iframeTitle={seoData.iframeTitleVideoAbout}/>
        {isMobile ? <Footer2Mobile /> : <Footer2 />}
      </div>
    </>
  );
};

export default About;
