export const whyWhatslegalContent = (`# Warum Frag-das-Gesetz.de?
  * **Anonym:** Bei WhatsLegal fragen Sie anonym – kein Login, keine E-Mail erforderlich.
  * **Kostenlos:** Frag-das-Gesetz.de ist kostenlos für 10 Fragen pro Tag (10 weitere Fragen für 2€).
  * **Vertrauensvoll:** Frag-das-Gesetz.de referenziert direkt auf deutsche oder EU Gesetze.
  * **Einfach:** Mit Frag-das-Gesetz.de kann jede*r Gesetze verstehen – egal ob 8 oder 80 Jahre.
          `);


//SEO DATA

export const seoData = {
  title: "FragDasGesetz freie Version",
  description: "Recht für alle mit dem KI Chatbot, der über 5.000 aktuelle deutsche Bundesgesetze und Verordnungen leicht zugänglich und verständlich macht.",
  keywords: "Recht für alle, deutsche Bundesgesetze, kostenlos, verständlich, anonymer Zugang, rechtliche Recherche",
  url: "https://www.frag-das-gesetz.de/",
  titleForIframWhyWhatslegal: "Freie Version von Frag das Gesetz"
};
