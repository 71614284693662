import React from 'react';
import './css/Articles.css';
import articleImage from "../assets/bgb-buergerliches-gesetzbuch-mit-ki-chatbot-fragen.jpg";
import { useNavigate } from 'react-router-dom';

interface DetailArticleCardProps {
    id: number;
    title: string;
    imageSrc: string;
    details: string;
    altText: string;
    content?: string; // Add content prop for the detail view
}

const DetailArticleCard: React.FC<DetailArticleCardProps> = ({ id, title, imageSrc, details, altText, content }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/news/${id}`, { state: { id, title, imageSrc, altText, content } });
    };

    return (
        <div className="article" onClick={handleClick}>
            <div className="image-container">
                <img src={articleImage} alt={altText} />
                <div className="overlay"></div>
            </div>
            {/* <span className="category">Announcement</span> */}
            <h3>{title}</h3>
            <p>{details} <a href="#">read more</a></p>
        </div>
    );
};

export default DetailArticleCard;
