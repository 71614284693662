import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer-Mobile';
import Footer2 from '../components/Footer';
import useWindowSize from '../hooks/useWindowSize';
import TextComponent from '../components/Text';
import { cookie_richtlinien, seoData } from '../data/cookie-richtlinien';
import SEO from '../components/Seo';

const CookiePolicy: React.FC = () => {
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  const mainContentStyle: React.CSSProperties = {
    flex: '1',
  };

  const { width } = useWindowSize();
  const isMobile = width <= 768;


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
        url={seoData.url}
      />
      <div style={containerStyle}>
        <Header />
        <div style={mainContentStyle}>

          <TextComponent content={cookie_richtlinien} />
        </div>
        {isMobile ? <Footer2Mobile /> : <Footer2 />}
      </div>
    </>
  );
};

export default CookiePolicy;
