import React from 'react';
import './css/About.css';
import ReactMarkdown from 'react-markdown';
import style from './css/markdown-tick-styles.module.css';
import rehypeRaw from 'rehype-raw';

interface AboutComponentProps {
    paragraphs: string[];
    backgroundColor?: string
}

const AboutWithTick: React.FC<AboutComponentProps> = ({ paragraphs, backgroundColor }) => {
    
    return (
        <section className="about"  style={{ backgroundColor: backgroundColor || '#ffffff' }}>
            <div className="about-content">
                {paragraphs.map((paragraph, index) => (
                     <ReactMarkdown key={index} className={style.reactMarkDown} rehypePlugins={[rehypeRaw]}>{paragraph}</ReactMarkdown>
                ))}
            </div>
        </section>
    );
};

export default AboutWithTick;
