import React, { useState } from 'react';
import './css/WhyWhatsLegal.css'; 
import AboutWithTick from './AboutWithTick';
import { seoData } from '../data/home-page';

interface Content {
  content: string;
  titleForIframe: string
}

const WhyWhatsLegal: React.FC<Content> = ({ content, titleForIframe}) => {

  const containerStyle: React.CSSProperties = {
    backgroundColor: "#f0f0f0"
  };
  
  return (
    <div className="about-video-container" style={containerStyle} >
    <div className="about-video-about" >

      <AboutWithTick paragraphs={[content]} backgroundColor='#f0f0f0' />
    </div>
    <div className="about-video-video">
      <iframe
        src="https://share.synthesia.io/embeds/videos/30eb92cf-8033-4b5e-99b7-0349b2c60eb1"
        frameBorder="0"
        allowFullScreen
        title={titleForIframe}
      ></iframe>
    </div>

  </div>

      );
}

export default WhyWhatsLegal;
