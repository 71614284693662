export const cookie_richtlinien = `Wir nutzen schlicht **keine Cookies**. Kein Tracking. Nichts.`



//SEO DATA

export const seoData = {
    title: "FragDasGesetz",
    description: "Nutzen Sie den kostenlosen KI Chatbot für Gesetze, der über 5.000 aktuelle deutsche Bundesgesetze und Verordnungen leicht zugänglich und verständlich macht.",
    keywords: "KI Chatbot, Gesetze, deutsche Bundesgesetze, kostenlos, verständlich, Gesetzesinformationen, anonymer Zugang, rechtliche Recherche",
    url: "https://www.whatslegal.com/",
  };