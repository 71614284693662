import React from 'react';
import './css/WhiteLabelsOffering.css';

interface WhiteLabelsOfferingsProps {
  embeddedImage: string;
  overlayImage: string;
  kiChatbotWhiteLabelEmbeddedAltText: string;
  kiChatbotWhiteLabelOverlayAltText: string
}

const WhiteLabelsOfferings: React.FC<WhiteLabelsOfferingsProps> = ({ embeddedImage, overlayImage, kiChatbotWhiteLabelEmbeddedAltText, kiChatbotWhiteLabelOverlayAltText }) => {
  return (
    <div className="version-container">
    <h2 className="main-title">Wir bieten White Label in zwei Versionen an</h2>
    <div className="version-display">
      <div className="version-column">
        <h2>Embedded Version</h2>
        <img src={embeddedImage} alt={kiChatbotWhiteLabelEmbeddedAltText} className="version-image" />
      </div>
      <div className="version-column">
        <h2>Overlay Version</h2>
        <img src={overlayImage} alt={kiChatbotWhiteLabelOverlayAltText} className="version-image" />
      </div>
    </div>
    </div>
  );
};

export default WhiteLabelsOfferings;
