// src/pages/Home.tsx
import React, { useState } from 'react';
import Header from '../components/Header';
import WhyWhatsLegal from '../components/WhyWhatsLegal';
import SecondHeader from '../components/SecondHeader';
import Footer2 from '../components/Footer';
import Footer2Mobile from '../components/Footer-Mobile';
import useWindowSize from '../hooks/useWindowSize';
import Hero from '../components/HeroSection';
import OverlayChatbot from '../components/OverlayChatbot';
import FloatingButton from '../components/FloatingButton';
import floatingChatIcon from "../assets/extra-images/smiley-comment-alt.png";
import homeImageMobile from '../assets/home-page/frau-liest-bundesgesetze-mit-frag-das-gesetz-mobile.jpg';
import homeImage from '../assets/home-page/frau-liest-bundesgesetze-mit-frag-das-gesetz-desktop.jpg';
import { title, subTitle, buttonText, secondBannerText, secondBannerSubText, features, seoData } from '../data/home-page';
import SEO from '../components/Seo';


const Home: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  const openChatbot = () => setIsChatbotOpen(true);
  const closeChatbot = () => setIsChatbotOpen(false);

  return (
    <>
      <SEO
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
        url={seoData.url}
      />
      <Header />
      <Hero normalImage={homeImage} altText={seoData.altTextBackground}
        mobileImage={homeImageMobile}
        heading={<div dangerouslySetInnerHTML={{ __html: title }} />}
        subHeading={<div dangerouslySetInnerHTML={{ __html: subTitle }} />}
        buttonHeading={buttonText}
        link='/chat' />
      <SecondHeader
        title={<div dangerouslySetInnerHTML={{ __html: secondBannerText }} />}
        secondTitle={<div dangerouslySetInnerHTML={{ __html: secondBannerSubText }} />}
      />
      <WhyWhatsLegal content={features} titleForIframe={seoData.titleForIframeFeatures}/>
      <FloatingButton
        onClick={openChatbot}
        icon={<img src={floatingChatIcon} alt="Chat Icon" />}
      />
      <OverlayChatbot isOpen={isChatbotOpen} onClose={closeChatbot} />
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </>
  );
};

export default Home;
