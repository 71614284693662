import React from 'react';
import './css/AboutVideo.css';
import AboutWithTick from './AboutWithTick';
import CustomButton from './CustomButton';
import { contactMail } from '../helpers/constants';

interface TextVideoProps {
  content: string;
  showButton: string;
  videoLink: string;
  buttonText?: string;
  backgroundColor?: string;
  iframeTitle?: string
}

const TextVideo: React.FC<TextVideoProps> = ({ content, showButton, videoLink, buttonText, backgroundColor, iframeTitle }) => {

  const handleEmailClick = () => {
    window.location.href = `mailto:${contactMail}?subject=Subject%20Here&body=Body%20text%20here`;
  };

  return (
    <div>
      <div className="about-video-container" style={{ backgroundColor: backgroundColor || '#ffffff' }}>
        <div className="about-video-about">
          <AboutWithTick paragraphs={[content]} backgroundColor={backgroundColor} />
          {(showButton === "true" && buttonText) && (
            <div className="button-container">
              <CustomButton onClick={handleEmailClick} text={buttonText} />
            </div>
          )}
        </div>
        <div className="about-video-video">
          <iframe
            src={videoLink}
            frameBorder="0"
            allowFullScreen
            title={iframeTitle}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default TextVideo;
