import React from 'react';
import useWindowSize from '../hooks/useWindowSize';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer-Mobile';
import Footer2 from '../components/Footer';
import SecondHeader from '../components/SecondHeader';
import FAQ from '../components/FAQ';
import SEO from '../components/Seo';
import { seoData } from '../data/faq-data';

const Faqs: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  const mainContentStyle: React.CSSProperties = {
    flex: '1',
  };

  return (
    <>
      <SEO
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
        url={seoData.url}
      />
      <div style={containerStyle}>
        <Header />
        <div style={mainContentStyle}>
          <FAQ />
        </div>
        {isMobile ? <Footer2Mobile /> : <Footer2 />}
      </div>
    </>
  );
};

export default Faqs;
