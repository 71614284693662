// src/components/SEO.tsx
import React from "react";
import { Helmet } from "react-helmet";
import FragDasGesetzLogo from "../assets/logos/whatslegal-new-logo/FragDasGesetz.png";

interface SEOProps {
    title: string;
    description: string;
    keywords?: string;
    url?: string;
}

const SEO: React.FC<SEOProps> = ({ title, description, keywords, url }) => (
    <Helmet>
        {/* Basic SEO */}
        <title>{title}</title>
        <meta name="description" content={description} />
        {keywords && <meta name="keywords" content={keywords} />}
        <meta name="author" content="WhatsLegal Team" />

        {/* Open Graph for Social Sharing */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={FragDasGesetzLogo} />

        {url && <meta property="og:url" content={url} />}
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={FragDasGesetzLogo} />

        {/* Structured Data for Rich Snippets */}
        <script type="application/ld+json">
            {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": title.replace(/<br \/>/g, " "),
                "description": description,
                "url": "https://www.whatslegal.com/",
                "publisher": {
                    "@type": "Organization",
                    "name": title.replace(/<br \/>/g, " "),
                },
                "about": description.replace(/[\n#*]/g, "").trim(),
                "image": FragDasGesetzLogo
            })}
        </script>
    </Helmet>
);

export default SEO;
