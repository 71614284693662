export const imprint = `
# Angaben gemäß § 5 TMG

**WhatsLegal GmbH**  
Widenmayerstrasse 46  
80538 München  

**Handelsregister:** HRB 293133  
**Registergericht:** München  

**Vertreten durch:**  
Stefan Weiss, Geschäftsführer  

**Telefon:** +49 179 6716336  
**E-Mail:** [stefan.weiss@whatslegal.ai](mailto:stefan.weiss@whatslegal.ai)

## Umsatzsteuer-ID

Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:

## EU-Streitschlichtung

Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:  
[https://ec.europa.eu/consumers/odr/](https://ec.europa.eu/consumers/odr/)

Unsere E-Mail-Adresse finden Sie oben im Impressum.

## Verbraucherstreitbeilegung
Wir sind nicht bereit oder verpflichtet,
 an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.

**Quelle:**  
[https://www.e-recht24.de/impressum-generator.html](https://www.e-recht24.de/impressum-generator.html)
`



//SEO DATA

export const seoData = {
    title: "FragDasGesetz",
    description: "Nutzen Sie den kostenlosen KI Chatbot für Gesetze, der über 5.000 aktuelle deutsche Bundesgesetze und Verordnungen leicht zugänglich und verständlich macht.",
    keywords: "KI Chatbot, Gesetze, deutsche Bundesgesetze, kostenlos, verständlich, Gesetzesinformationen, anonymer Zugang, rechtliche Recherche",
    url: "https://www.whatslegal.com/",
  };