import React from 'react';
import './css/Hero.css';
import { useNavigate } from 'react-router-dom';

interface HeroProps {
  normalImage: string;
  mobileImage: string;
  heading: React.ReactNode,
  subHeading: React.ReactNode,
  buttonHeading: string,
  link: string,
  altText: string
}

const Hero: React.FC<HeroProps> = ({ normalImage, mobileImage, heading, subHeading, buttonHeading, link, altText}) => {

  const navigate = useNavigate();

  const handleClick = () => {
    if (link.startsWith('http')) {
      window.open(link, '_blank');
    } else {
      navigate(link);
    }
  };


  return (
    <section className="hero"
      style={
        {
          '--hero-background': `url(${normalImage})`,
          '--hero-background-mobile': `url(${mobileImage})`,
        } as React.CSSProperties
      }
    >
       {/* Hidden image for accessibility */}
       <img
        src={normalImage}
        alt={altText}
        className="visually-hidden"
      />

      <div className="hero-content">
        <h1>{heading}</h1>
        <p>{subHeading}</p>
        {/* <p>unserer gen. AI platform</p> */}
        <button className="hero-button" onClick={handleClick}>
          {buttonHeading}
        </button>
      </div>
    </section>
  );
};

export default Hero;
