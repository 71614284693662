import React from 'react';
import './css/Footer.css';
import { faYoutube, faTiktok, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { ReactComponent as FooterLogoSvg } from "../assets/logos/whatslegal-new-logo/FragDasGesetz.svg";
import { altTextOfLogo, subTitle, title } from '../data/footer';

const Footer2: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-links-section">
                    <div className="footer-column">
                        <div className="footer-logo-section">
                            <FooterLogoSvg aria-label={altTextOfLogo}/>
                            <h3>{title}</h3>
                            <h3>{subTitle}</h3>
                            {/* <div className="footer-tagline">um Demokratie und Rechtsstaatlichkeit zu stärken </div>
                            <div className="footer-tagline">Kostenlos, einfach, anynom </div> */}
                        </div>
                    </div>
                    <div className="footer-column">
                        <h3>COMPANY</h3>
                        <ul>
                            <li><NavLink to="/news" >News</NavLink></li>
                            <li><NavLink to="/about" >About</NavLink></li>
                            <li><NavLink to="/faqs" >FAQs</NavLink></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h3>LEGAL</h3>
                        <ul>
                            <li><NavLink to="/impressum" >Impressum</NavLink></li>
                            <li><NavLink to="/datenschutz" >Datenschutz</NavLink></li>
                            <li><NavLink to="/nutzungsbedingungen" >Nutzungsbedingungen</NavLink></li>
                            <li><NavLink to="/cookie-richtlinien" >Cookie-Richtlinien</NavLink></li>
                            {/* <li><NavLink to="/tips-tricks" >Tips&Tricks</NavLink></li> */}
                            <li><NavLink to="/agbs" >AGBs</NavLink></li>

                        </ul>
                    </div>
                    <div className="footer-column">
                        <h3>FOLLOW US</h3>
                        <div className="social-icons">

                            <a href="https://www.linkedin.com/company/101649967/admin/dashboard/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                            <a href="https://www.youtube.com/channel/UCxaKl8jzmhD4698nI_XSjTA" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>
                            <a href="https://www.tiktok.com/@whatslegal.ai" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTiktok} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer2;
