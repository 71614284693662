import React, { useEffect } from 'react';
import Footer2Mobile from '../components/Footer-Mobile';
import Footer2 from '../components/Footer';
import useWindowSize from '../hooks/useWindowSize';
import Blog from '../components/ArticleDetail';
import Header from '../components/Header';
import SEO from '../components/Seo';
import { seoData } from '../data/articles-data';

const ArticlePage: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
       <SEO
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
        url={seoData.url}
      />
      <Header />
      <Blog />
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </>
  );
}

export default ArticlePage;
