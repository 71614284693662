// OverlayChatbot.tsx
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import './css/OverlayChatbot.css';
import { FaCompressArrowsAlt, FaExpandArrowsAlt } from 'react-icons/fa';
import { ReactComponent as DropdownSvg } from "../assets/svgs/dropdown.svg";
import { ReactComponent as LawLinks } from "../assets/svgs/lawLinks.svg";
import { ReactComponent as SenderSvg } from "../assets/svgs/sender.svg";
import { ReactComponent as MinimizeSvg } from "../assets/svgs/minimize-svgrepo-com.svg";
import { ReactComponent as MaximizeSvg } from "../assets/svgs/maximize-svgrepo-com.svg";
import { ReactComponent as CloseSvg } from "../assets/svgs/close-svgrepo-com.svg";
import { ReactComponent as HamburgerHorizontalIcon } from "../assets/svgs/hamburger-horizontal.svg";
import { Law, Message, Messages, MessagesList } from '../helpers/types';
import axios, { CancelTokenSource } from 'axios';
import { BASE_URL } from '../helpers/constants';
import { filterHistory, getErrorMessage, getFormattedTime } from '../helpers/utils';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { ReactComponent as FragDasGesetz } from "../assets/logos/whatslegal-new-logo/FragDasGesetz.svg";
import ReactMarkdown from 'react-markdown';
import style from './css/overlay-markdown-tick-styles.module.css';
import whatsLegalHeaderLogo from "../assets/logos/whatslegal-new-logo/FragDasGesetz.png";
import { altTextOfLogo } from '../data/header';
import { altTextOfLogo as altTextOfLogoFooter } from '../data/footer';

interface OverlayChatbotProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const OverlayChatbot: React.FC<OverlayChatbotProps> = ({ isOpen = false, onClose }) => {

  const [isFullscreen, setIsFullscreen] = useState(false);
  const toggleFullscreen = () => setIsFullscreen(!isFullscreen);
  const [messages, setMessages] = useState<Messages>(
    JSON.parse(sessionStorage.getItem("overlaymessages") ?? "{}")
  );
  const [messagesList, setMessagesList] = useState<MessagesList>(
    JSON.parse(sessionStorage.getItem("overlaymessagesList") ?? "{}")
  );
  const [messageToggle, setMessageToggle] = useState(false);
  const [messageCompleted, setMessageCompleted] = useState(true);
  const [chatInput, setChatInput] = useState<string>("");
  const [DS1, setDS1] = useState(true);
  const [DS2, setDS2] = useState(false);
  const [DS3, setDS3] = useState(false);
  const [selectedLaws, setSelectedLaws] = useState<Law[]>(["fed"]);
  const [DS4, setDS4] = useState(false);
  const [DS5, setDS5] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [messageLoading, setMessageLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [userToken, setUserToken] = useState(
    localStorage.getItem("overlayuserToken") ||
    sessionStorage.getItem("overlayuserToken") ||
    ""
  );
  const [uId, setUId] = useState(
    localStorage.getItem("overlayuserId") ||
    sessionStorage.getItem("overlayuserId") ||
    ""
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("overlayuserRefreshToken") ||
    sessionStorage.getItem("overlayuserRefreshToken") ||
    ""
  );

  const [convID, setConvId] = useState(sessionStorage.getItem("convId") || "");

  const [remainingChance, setRemainingChance] = useState(sessionStorage.getItem("overlayremainingChances") || "");
  const [purchasedChances, setPurchasingChance] = useState(sessionStorage.getItem("overlaypurchasedChances") || "");

  const [history, setHistory] = useState(
    JSON.parse(sessionStorage.getItem("overlayhistory") ?? "{}")
  );
  const [historyFiltered, setHistoryFiltered] = useState(
    JSON.parse(sessionStorage.getItem("overlayhistoryFiltered") ?? "{}")
  );
  const [searchValue, setSearchValue] = useState("");
  const [guestLogin, setGuestLogin] = useState(true);
  const [userLogin, setUserLogin] = useState(
    localStorage.getItem("userLogin") ||
    sessionStorage.getItem("userLogin") ||
    ""
  );
  const [subscriberLogin, setSubscriberLogin] = useState(
    localStorage.getItem("subscriberLogin") ||
    sessionStorage.getItem("subscriberLogin") ||
    ""
  );
  const [controller, setController] = useState<AbortController | null>(null);
  const [cancelTokens, setCancelTokens] = useState<CancelTokenSource[]>([]);
  const [showGuestLimitError, setShowGuestLimitError] = useState(false);
  const [showUserLimitError, setUserLimitError] = useState(false);
  const [disclaimerChecked, setDisclaimerChecked] = useState(
    localStorage.getItem("overlaydisclaimerChecked") ||
    sessionStorage.getItem("overlaydisclaimerChecked") ||
    ""
  );
  const [popupTimer, setPopupTimer] = useState(5);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [closing, setClosing] = useState(false);
  const [showErrorDisclaimer, setShowErrorDisclaimer] = useState(false);
  const agbRef = useRef<HTMLInputElement>(null);
  const disclaimerRef = useRef<HTMLInputElement>(null)

  const [settingCode, setSettingCode] = useState(
    localStorage.getItem("settingCode") ||
    sessionStorage.getItem("settingCode") ||
    ""
  );
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [isMultiline, setIsMultiline] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPopupTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    if (popupTimer === 0) {
      setShowCloseButton(true);
      clearInterval(intervalId);
      setClosing(true)
      setTimeout(() => {
        setShowAddModal(false);
        setClosing(false)
      }, 2000); // Fading out takes 1 second
    }

    return () => clearInterval(intervalId);
  }, [popupTimer]);



  async function checkAndRefreshToken() {
    setUserToken(await refreshUserToken());
  }

  useEffect(() => {
    let intervalId: any;
    if (userToken) {
      checkAndRefreshToken();
      intervalId = setInterval(() => {
        checkAndRefreshToken();
      }, 59 * 60 * 1000); // Every 59 minutes
    }
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (userToken) {
      getConversationIDs(userToken);
    }
  }, [userToken, messageToggle]);

  async function getConversationIDs(userToken: string) {
    setHistoryLoading(true);
    try {
      let token = userToken;
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "get_convers_ids",
        {
          token: token,
        },
        {
          cancelToken: source.token,
        }
      );
      const { convers_ids } = response.data;
      setHistory(convers_ids);
      sessionStorage.setItem("overlayhistory", JSON.stringify(convers_ids));
      const filteredHistory = filterHistory(convers_ids);
      setHistoryFiltered(filteredHistory);
      sessionStorage.setItem(
        "overlayhistoryFiltered",
        JSON.stringify(filteredHistory)
      );
    } catch (error: any) {
      // toast.error(getErrorMessage(error));
      console.error(error);
    } finally {
      setHistoryLoading(false);
    }
  }

  async function newChat(token: string) {
    try {
      const createConvData: any = {
        question: chatInput,
      };
      //if (!guestLogin) {
      createConvData.token = token;
      createConvData.code = settingCode;
      //}

      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + `${(guestLogin || userLogin) ? "create_guest_convers" : "create_convers"}`,
        createConvData,
        {
          cancelToken: source.token,
        }
      );
      const { convers_id } = response.data;
      setConvId(convers_id);
      sessionStorage.setItem("convId", convers_id);
      return convers_id;
    } catch (error: any) {
      if (error.response?.data?.message?.includes("Daily limit")) {
        const tempUserLogin = userLogin
        if (tempUserLogin) {
          setUserLimitError(true)
          setChatInput("");
          setIsMultiline(false);
        } else {
          logoutAction();
        }
        setShowAddModal(false)
        setShowGuestLimitError(true);

        return;
      }
      toast.error(getErrorMessage(error));
      console.error(error);
      setMessageLoading(false);
      setMessageCompleted(true);
    }
  }

  const getUserId = async (idToken: string) => {
    try {
      const getUidData: any = {
        token: idToken
      };

      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "/get_UID",
        getUidData,
        {
          cancelToken: source.token,
        }
      );
      const { UID } = response.data;
      if (UID) {
        setUId(UID)
        sessionStorage.setItem("overlayuserId", UID);
      }
    } catch (error: any) {
      console.error("Error getting user id", error);
    }
  }

  async function checkGuestLimit(token?: string) {
    try {
      const createGuestLimitData: any = {
        token: token ?? ""
      };
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "remaining_convers_chances",
        createGuestLimitData,
        // BASE_URL + `remaining_convers_chances`,
        {
          cancelToken: source.token,
        }
      );
      const { remaining_chances, purchased_chances } = response.data;
      setRemainingChance(remaining_chances);
      setPurchasingChance(purchased_chances)
      sessionStorage.setItem("overlayremainingChances", remaining_chances);
      sessionStorage.setItem("overlaypurchasedChances", purchased_chances);
      return { remaining_chances, purchased_chances };
    } catch (error: any) {
      console.error("Error fetching remaining chances from the api", error);
      throw error;
    }
  }

  async function refreshUserToken() {
    try {
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "refresh_token",
        {
          token: refreshToken,
        },
        {
          cancelToken: source.token,
        }
      );
      const { id_token } = response.data;
      setUserToken(id_token);
      sessionStorage.setItem("overlayuserToken", id_token);
      getUserId(id_token);
      return id_token;
    } catch (error) {
      // toast.error(getErrorMessage(error));
      console.error(error);
    }
  }

  async function getLastMessageURL(conversId: string) {
    try {
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + `${guestLogin ? "get_guest_urls" : "get_urls"}`,
        {
          token: userToken,
          convers_id: conversId,
        },
        {
          cancelToken: source.token,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error));
    }
  }

  const sendMessage = async () => {
    if (!chatInput || chatInput.trim().length <= 0) return;
    if (disclaimerChecked === "false" || !disclaimerChecked) {
      setDisclaimerChecked("false")
      setShowErrorDisclaimer(true)
      if (!disclaimerChecked && disclaimerRef.current && disclaimerRef.current.parentElement) {
        disclaimerRef.current.parentElement.classList.add('focused');
      }
      return;
    }
    window.scrollTo(0, 0);
    setMessageLoading(true); // Set loading to true when button is clicked
    setProgress(0);

    let responseMsg = "";
    let msgObj: Message = {
      user: chatInput,
      assistant: responseMsg,
      timestamp: new Date().toISOString(),
    };
    const newMessages: Messages = { "0": msgObj };
    let index = 1;
    for (const key in messages) {
      newMessages[index.toString()] = messages[key];
      index++;
    }
    setMessages(newMessages);

    if (subscriberLogin != "true") {
      checkGuestLimit(userToken).then((data) => {
        if (data) {
          const { remaining_chances, purchased_chances } = data;
          if (remaining_chances > 0 || remaining_chances === undefined || purchased_chances > 0 || settingCode === "Nei2K32wLMefX5L8oTy7V") {
            showResponseOfMessage(responseMsg, msgObj, newMessages)
          } else {
            delete newMessages["0"];
            setMessages(newMessages);
            if (userLogin === "true") {

              setShowSubscribeModal(true)
            } else {
              setShowGuestLimitError(true);
            }
            setMessageLoading(false);
            setMessageCompleted(true);
            return
          }
        }
      }).catch((error) => {
        console.error("Error:", error);
        toast.error(getErrorMessage(error));
        setMessageLoading(false);
        setMessageCompleted(true);
      });
    } else {
      showResponseOfMessage(responseMsg, msgObj, newMessages)
    }
  };

  async function showResponseOfMessage(responseMsg: string, msgObj: any, messages: any) {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        const diff = 0.001 * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 0);
    try {
      let token = userToken;
      let msgList = messages;
      let conversId = convID;
      if (!convID || isEmpty(messages) || guestLogin) {
        if (guestLogin) msgList = {};
        conversId = await newChat(token);
      }

      if (!conversId) return;

      const chatData: any = {
        content: chatInput,
        lang: "English",
        convers_id: conversId,
      };
      if (!guestLogin) {
        chatData.token = token;
      }

      chatData.values = selectedLaws
      chatData.code = settingCode
      const charHeaders = {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };


      // msgList = [...messages, msgObj];

      if (chatInput) {
        setChatInput("");
        setIsMultiline(false);
      }

      const abortController = new AbortController();
      setController(abortController);

      const response = await fetch(
        BASE_URL + `${guestLogin ? "guest_chat" : "chat"}`,
        {
          method: "post",
          signal: abortController.signal,
          headers: charHeaders,
          body: JSON.stringify(chatData),
        }
      );
      if (!response.ok || !response.body) {
        if (guestLogin && response.status === 400) {
          toast.error(
            "A guest can ask only 1 question per conversation and a total of 3 questions."
          );
        }
        if (response.status === 429) {
          toast.error("User limited completed")
        }
        throw response.statusText;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const decodedChunk = decoder.decode(value, { stream: true });
        responseMsg += decodedChunk; // update state with new chunk
        msgObj = {
          ...msgObj,
          user: chatInput,
          assistant: responseMsg,
        };
        msgList["0"] = msgObj;
        setMessages(msgList);
        setMessageCompleted(false);
      }
      const urlData = await getLastMessageURL(conversId);
      msgObj.titles = urlData?.titles;
      msgObj.urls = urlData?.urls;
      sessionStorage.setItem("overlaymessages", JSON.stringify(msgList));
      setMessageToggle(!messageToggle);
      // history for guest
      updateHistory(conversId, chatInput);
      updateMessagesList(conversId, msgList);
    } catch (error: any) {
      removeUncompletedMessage();
      toast.error(getErrorMessage(error));
      console.error(error);
    } finally {
      clearInterval(timer);
      setMessageLoading(false);
      setMessageCompleted(true);
    }
  }

  function removeUncompletedMessage() {
    const updatedMsgs = Object.fromEntries(
      Object.entries(messages).filter(
        ([key, value]: [string, Message]) => value.assistant ?? false
      )
    );
    setMessages(updatedMsgs);
  }

  function updateHistory(conversId: string, chatInput: string) {
    const updatedHistory = { ...history };
    if (!updatedHistory[conversId]) {
      updatedHistory[conversId] = {
        user: chatInput,
        timestamp: new Date(),
      };
      setHistory(updatedHistory);
      sessionStorage.setItem("history", JSON.stringify(updatedHistory));
      const filteredHistory = filterHistory(updatedHistory);
      setHistoryFiltered(filteredHistory);
      sessionStorage.setItem(
        "overlayhistoryFiltered",
        JSON.stringify(filteredHistory)
      );
    }
  }

  function updateMessagesList(conversId: string, msgList: Messages) {
    const updateMessagesList = { ...messagesList, [conversId]: msgList };
    setMessagesList(updateMessagesList);
    sessionStorage.setItem("overlaymessagesList", JSON.stringify(updateMessagesList));
  }

  const logoutAction = () => {
    localStorage.clear();
    sessionStorage.clear();
    setChatInput("");
    setUserToken("");
    setRefreshToken("");
    setMessages({});
    setMessagesList({});
    setHistory({});
    setHistoryFiltered({});
    setConvId("");
    setGuestLogin(true);
    setUserLogin("");
    setSubscriberLogin("");
    cancelAllRequests();
    setShowGuestLimitError(false);
    setUserLimitError(false)
    setUId("")
  };


  const cancelAllRequests = () => {
    if (!isEmpty(cancelTokens)) {
      cancelTokens.forEach((token) => {
        token.cancel("Request canceled by user");
      });
      setCancelTokens([]);
    }

    if (controller) {
      controller.abort();
      setController(null);
    }
  };

  const handleDisclaimerCheckChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const isChecked = e.target.checked;
    sessionStorage.setItem("overlaydisclaimerChecked", isChecked.toString());
    setDisclaimerChecked(isChecked ? "true" : "false");
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setChatInput(value);
    autoResizeTextarea(); // Automatically resize the textarea on change
  };


  // Function to handle enter key
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        e.preventDefault(); // Prevent the default Enter behavior (submitting the form)

        const textarea = e.currentTarget;
        const cursorPosition = textarea.selectionStart;
        const textBeforeCursor = chatInput.slice(0, cursorPosition);
        const textAfterCursor = chatInput.slice(cursorPosition);

        setChatInput(textBeforeCursor + '\n' + textAfterCursor);
      } else {
        e.preventDefault();
        sendMessage();
      }
    }
  };
  // Auto-resize the textarea height dynamically as content grows
  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset the height to auto
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set the height to match the scrollHeight
      const rows = textareaRef.current.scrollHeight > 40;
      setIsMultiline(rows);
    }
  };

  useEffect(() => {
    autoResizeTextarea(); // Resize on initial load and whenever input changes
  }, [chatInput]);

  const updateSelectedLaws = (law: Law, shouldAdd: boolean) => {
    setSelectedLaws(prevLaws => {
      if (shouldAdd) {
        // Add the law if it's not already included
        return prevLaws.includes(law) ? prevLaws : [...prevLaws, law];
      } else {
        // Remove the law if it's included
        return prevLaws.filter(existingLaw => existingLaw !== law);
      }
    });
  };

  return (
    <>
      {isOpen && (<div className={`chatbot-wrapper ${isFullscreen ? 'fullscreen' : ''}`}>
        <div className="chatbot-header">
          <img src={whatsLegalHeaderLogo} className="overlay-websiteLogo" alt={altTextOfLogo}/>
          <div>
            <button className="enlarge-button" onClick={toggleFullscreen}>
              {isFullscreen ? <MinimizeSvg /> : <MaximizeSvg/>}
            </button>
            <button className="close-button" onClick={onClose}><CloseSvg/></button>
          </div>
        </div>


        <form onSubmit={(e) => e.preventDefault()}>
            <div className={`d-flex ${isMultiline ? 'overlay-chatBoxWithText' : 'overlay-chatBox'}`}>
              <div className="d-flex">


                <div className="overlay-dropdown">
                  <button
                    className="overlay-ds-dropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <HamburgerHorizontalIcon className='lawIconSvg'/>
                  </button>

                  <ul
                    className="dropdown-menu"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        <label
                          htmlFor="ds1"
                          className="d-flex flex-column"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              id="ds1"
                              checked={DS1}
                              onChange={() => {
                                setDS1(prevDS1 => {
                                  const newDS1 = !prevDS1;
                                  updateSelectedLaws("fed", newDS1);
                                  return newDS1;
                                });
                              }}
                            />
                            <span className='overlay-dropdown-text'>Bundesgesetze & -verordnungen</span>
                          </div>

                          <p className="m-0 ms-4 overlay-small-paragraph">
                            (1.792 Gesetze & 2.854 Verordnungen; Stand Aug. 2024)
                          </p>
                        </label>

                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item " href="#">
                        <label
                          htmlFor="ds5"
                          className="d-flex flex-column"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input checkbox-bg me-2"
                              id="ds5"
                              checked={DS5}
                              onChange={() => {
                                setDS5(prevDS5 => {
                                  const newDS5 = !prevDS5;
                                  updateSelectedLaws("eu_ai", newDS5);
                                  return newDS5;
                                });
                              }}
                            />
                            <span className='overlay-dropdown-text'>EU AI Act</span>
                          </div>
                          <p className="m-0 ms-4 overlay-small-paragraph">
                            (VERORDNUNG (EU) 2024/1689 v. 13 Juni 2024 )
                          </p>
                        </label>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item " href="#">
                        <label
                          htmlFor="ds2"
                          className="d-flex flex-column"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input checkbox-bg me-2"
                              id="ds2"
                              checked={DS2}
                              onChange={() => {
                                setDS2(prevDS2 => {
                                  const newDS2 = !prevDS2;
                                  updateSelectedLaws("bayGe", newDS2);
                                  return newDS2;
                                });
                              }}
                            />
                            <span className='overlay-dropdown-text'>Bayern - Gesetze</span>
                          </div>
                          <p className="m-0 ms-4 overlay-small-paragraph">
                            (242 Gesetze; Stand Aug. 2024)
                          </p>
                        </label>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item " href="#">
                        <label
                          htmlFor="ds3"
                          className="d-flex flex-column"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              id="ds3"
                              checked={DS3}
                              onChange={() => {
                                setDS3(prevDS3 => {
                                  const newDS3 = !prevDS3;
                                  updateSelectedLaws("bayVe", newDS3);
                                  return newDS3;
                                });
                              }}
                            />
                            <span className='overlay-dropdown-text'>Bayern - Verordnungen</span>
                          </div>
                          <p className="m-0 ms-4 overlay-small-paragraph">
                            (506 Verordnungen; Stand Aug. 2024)
                          </p>
                        </label>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item disabled" href="#">
                        <label
                          htmlFor="ds4"
                          className="d-flex check-group"
                          style={{ cursor: "pointer" }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input me-2"
                            id="ds4"
                            checked={DS4}
                            onChange={() => setDS4(!DS4)}
                          />
                          <span className='overlay-dropdown-text'>weitere Datensätze (demnächst)</span>
                        </label>
                      </a>
                    </li>
                    <li className="text-center mt-2">
                      <button className="btn overlay-upload-btn disabled">Upload</button>
                    </li>
                  </ul>
                </div>
              </div>

              <textarea
                ref={textareaRef}
                className="overlay-chatInput"
                id="chat"
                placeholder="Frage hier eingeben"
                value={chatInput}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                rows={1}
              />
              <div className="d-flex">
                <button
                  className={`overlay-sendBtn`}
                  onClick={sendMessage}
                  disabled={messageLoading ? true : false}
                  style={{
                    cursor: `${messageLoading ? "not-allowed" : "pointer"}`,
                  }}>
                  <SenderSvg className='sendButtonSvg'/>
                </button>
              </div>
            </div>
          </form>
        <div className="chatbot-body">
          {isEmpty(messages) ? (
            <div className="overlay-landing-section">
              <div className="overlay-questionsContainer">
                <button
                  className="overlay-questionButton"
                  onClick={() => {
                    setChatInput("Mein Flug hatte Verspätung. Bekomme ich mein Geld zurück?");
                  }}
                >
                  Mein Flug hatte Verspätung. Bekomme ich mein Geld zurück?
                </button>
                <button
                  className="overlay-questionButton"
                  onClick={() => {
                    setChatInput("Mir wurde gekündigt. Bekomme ich eine Abfindung?");
                  }}
                >
                  Mir wurde gekündigt. Bekomme ich eine Abfindung?
                </button>
                <button
                  className="overlay-questionButton"
                  onClick={() => {
                    setChatInput("Ich bin in der 30er Zone zu schnell gefahren. Was passiert nun?");
                  }}
                >
                  Ich bin in der 30er Zone zu schnell gefahren. Was passiert nun?
                </button>
              </div>
              <div className="overlay-content-wrapper">
                <div className="overlay-line-separator">
                  <hr className="overlay-line" />
                  <span className="overlay-or-text">Disclaimer/ Haftungsausschluss</span>
                  <hr className="overlay-line" />
                </div>
                <div className="overlay-landing-para form-group form-check">
                  <input
                    type="checkbox"
                    className={`form-check-input clickable ${showErrorDisclaimer && (disclaimerChecked === "false") ? 'error-disclaimerChecked' : ''}`}
                    id="accept-terms-2"
                    checked={disclaimerChecked === "true"}
                    onChange={handleDisclaimerCheckChange}
                    ref={disclaimerRef}

                  />
                  <label htmlFor="accept-terms-2" className={`overlay-clickable ${(showErrorDisclaimer && (disclaimerChecked === "false")) ? 'error-label-disclaimerChecked' : ''}`}>
                    Mir ist bewusst: FragDasGesetz.de ist nur eine maschinengenerierte Zusammenfassung gesetzlicher Bestimmungen und liefert möglicherweise keine vollständige und angemessene Antwort, auch weil jede rechtliche Einordnung davon abhängt, ob alle relevanten Tatsachen offengelegt werden. Die einzelnen Ergebnisse können sich wesentlich ändern, wenn weitere Tatsachen mitgeteilt werden, die vorher nicht eingegeben wurden.  Es ist keine juristische Beratungsleistung durch eine/n Jurist/in und ersetzt diese nicht. Es kommt kein Mandatsverhältnis zustande. Wenden Sie sich in jedem Fall an eine/n fachkundige/n Jurist/-in. Geben Sie keine personenbezogenen Daten ein.
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <div className="overlay-message-section">
              {Object.entries(
                Object.fromEntries(
                  Object.entries(messages).filter(
                    ([key, value]) => "assistant" in value
                  )
                ))
                .map(([key, value]: [string, Message], index, array) => (
                  <div key={key}>
                    <div className="overlay-user-div">
                      <div className="overlay-user">
                        <div
                          className="overlay-messageText"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {value.user}
                        </div>
                      </div>
                    </div>
                    <div className="overlay-assistant">
                      <div>
                        <span className="me-3 fw-bold d-flex align-items-center" >
                          <FragDasGesetz aria-label={altTextOfLogoFooter}/>
                          <span className="overlay-time ms-3">
                            {getFormattedTime(value.timestamp)}
                          </span>
                        </span>
                      </div>
                      <div
                        className="overlay-messageText">
                        {value.assistant ? (
                          <ReactMarkdown className={style.reactMarkDown}>{value.assistant}</ReactMarkdown>
                        ) : (
                          <div
                            className="spinner-grow"
                            role="status"
                            style={{ color: "#0d3646" }}
                          />
                        )}
                      </div>
                      {!isEmpty(value.titles) && (
                        <div>
                          <div className="overlay-chat-links mt-3">
                            <div className="fw-bold mb-1">Gesetz(e): </div>
                            {value.titles?.map((_, index) => (
                              <div
                                className="d-flex clickable"
                                style={{ gap: "10px" }}
                                key={index}
                              >
                                <div className="overlay-lawLinks">
                                  <LawLinks />
                                </div>
                                <div style={{ flex: 1 }}>
                                  <a
                                    href={value.urls?.[index] ?? ""}
                                    target="_blank"
                                    className="overlay-link-text"
                                  >
                                    {value.titles?.[index] ?? ""}
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="overlay-disclaimer">
                            FragDasGesetz.de ist nur eine maschinengenerierte Zusammenfassung gesetzlicher Bestimmungen und stellt keine juristische Beratungsleistung durch einen Mensch dar und ersetzt diese auch nicht. Auch überzeugend erscheinende Antworten sollten ggf. durch eine fachkundige Person (z.B. Rechtsanwalt/-in) überprüft werden.
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}

        </div>
      </div>
      )}
    </>
  );
};

export default OverlayChatbot;
