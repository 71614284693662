import React from 'react';
import './css/ArticleDetail.css';
import DetailArticleCard from './DetailArticleCard';
import { articlesData, seoData } from '../data/articles-data';


const ArticleDetail: React.FC = () => {
  return (
    <main className="articleDetail container">
      <div className="articlePageList">
      {articlesData.map((article) => (
          <DetailArticleCard
            key={article.id}
            id={article.id}
            title={article.title}
            imageSrc={article.imageSrc}
            details={article.altText}
            altText={seoData.altText}
            content={article.content}
          />
        ))}
      </div>
    </main>
  );
};

export default ArticleDetail;
