export const purpose = `# Purpose:
Die Idee zu Frag-das-Gesetz entstand aus dem Wunsch, einen Beitrag zur Stärkung der Demokratie und Rechtsstaatlichkeit in Deutschland zu leisten. Wir stellten uns die Frage: Wie können wir mithilfe von Technologie den sperrigen Zugang zum Recht vereinfachen und den Inhalt verständlicher machen, damit alle Bürger*innen unsere Gesetze verstehen? Egal ob 8 Jahre oder 80 Jahre.

Aus der Idee ist eine gen. AI Plattform für rechtliches Wissensmanagement geworden und derzeit arbeiten wir an einer Pro- und Enterprise Version.
`

export const featuresContent = `# Features:
  * Macht Bundesgesetze und Landesgesetze für alle Bürger*innen leicht zugänglich und verständlich.
  * Bietet eine vertrauensvolle, anonyme Informationsquelle zur Selbsteinschätzung der eigenen rechtlichen Lage (keine Cookies, kein Tracking, keine Anmeldung, keine persönlichen Daten).
  * Unterstützt Laien (und Profis) beim Recherchieren in Gesetzen.
  * Befähigt (Legal Enablement) Bürger*innen zum Konsens durch Konfliktvermeidung.**
  * Trägt potenziell zur Entlastung der Zivilgerichte bei.**
  * Ist kostenlos.
  
****[Abschlussbericht „Erforschung der Ursachen des Rückgangs der Eingangszahlen bei den Zivilgerichten”](https://www.bmj.de/SharedDocs/Downloads/DE/Fachinformationen/Abschlussbericht_Eingangszahlen_Zivilgerichte.html)**`;
  
  
//SEO DATA

export const seoData = {
  title: "FragDasGesetz",
  description: "Nutzen Sie den kostenlosen KI Chatbot für Gesetze, der über 5.000 aktuelle deutsche Bundesgesetze und Verordnungen leicht zugänglich und verständlich macht.",
  keywords: "KI Chatbot, Gesetze, deutsche Bundesgesetze, kostenlos, verständlich, Gesetzesinformationen, anonymer Zugang, rechtliche Recherche",
  url: "https://www.whatslegal.com/",
  iframeTitleVideoAbout: "title"
};