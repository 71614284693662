import React, { ChangeEvent, FormEvent, useEffect, useRef, useState, KeyboardEvent, MouseEvent } from "react";
import {
  filterHistory,
  getErrorMessage,
  getFormattedTime,
} from "../helpers/utils";
import { Modal } from "react-bootstrap";
import {
  FilteredHistory,
  HistoryObj,
  Law,
  Message,
  Messages,
  MessagesList,
} from "../helpers/types";
import {
  BASE_URL,
  googleAnylaticsToken,
  LOGIN_ENDPOINT,
  SIGNUP_ENDPOINT,
} from "../helpers/constants";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import axios, { CancelTokenSource } from "axios";
import { isEmpty } from "lodash";
import ReactMarkdown from "react-markdown";
import ReactGA from "react-ga4";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import whatsLegalLogo from "../assets/logos/whatslegal-new-logo/FragDasGesetz.png";
import './css/Popup.css';
import './css/ChatFrame.css';
import placeHolderAddImage from "../assets/avin.jpeg";
import PaypalButton from "./PaypalButton";
import { ReactComponent as FragDasGesetz } from "../assets/logos/whatslegal-new-logo/FragDasGesetz.svg";
import { ReactComponent as HamburgerHorizontalIcon } from "../assets/svgs/hamburger-horizontal.svg";
import { altTextOfLogo } from "../data/footer";

const ChatBot: React.FC = () => {
  const [messages, setMessages] = useState<Messages>(
    JSON.parse(sessionStorage.getItem("messages") ?? "{}")
  );
  const [messagesList, setMessagesList] = useState<MessagesList>(
    JSON.parse(sessionStorage.getItem("messagesList") ?? "{}")
  );
  const [messageToggle, setMessageToggle] = useState(false);
  const [messageCompleted, setMessageCompleted] = useState(true);
  const [messageID, setMessageID] = useState("");
  const [chatInput, setChatInput] = useState<string>("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [collapsed, setCollapsed] = useState(true);
  const [mobileCollapsed, setMobileCollapsed] = useState(true);
  const [DS1, setDS1] = useState(true);
  const [DS2, setDS2] = useState(false);
  const [DS3, setDS3] = useState(false);
  const [selectedLaws, setSelectedLaws] = useState<Law[]>(["fed"]);
  const [DS4, setDS4] = useState(false);
  const [DS5, setDS5] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [messageLoading, setMessageLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(
    JSON.parse(localStorage.getItem("termsAccepted") || "false")
  );
  const [userToken, setUserToken] = useState(
    localStorage.getItem("userToken") ||
    sessionStorage.getItem("userToken") ||
    ""
  );
  const [uId, setUId] = useState(
    localStorage.getItem("userId") ||
    sessionStorage.getItem("userId") ||
    ""
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("userRefreshToken") ||
    sessionStorage.getItem("userRefreshToken") ||
    ""
  );

  const [convID, setConvId] = useState(sessionStorage.getItem("convId") || "");

  const [remainingChance, setRemainingChance] = useState(sessionStorage.getItem("remainingChances") || "");
  const [purchasedChances, setPurchasingChance] = useState(sessionStorage.getItem("purchasedChances") || "");

  const [history, setHistory] = useState(
    JSON.parse(sessionStorage.getItem("history") ?? "{}")
  );
  const [historyFiltered, setHistoryFiltered] = useState(
    JSON.parse(sessionStorage.getItem("historyFiltered") ?? "{}")
  );
  const [showLinksAndIcons, setShowLinksAndIcons] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [guestLogin, setGuestLogin] = useState(true);
  const [userLogin, setUserLogin] = useState(
    localStorage.getItem("userLogin") ||
    sessionStorage.getItem("userLogin") ||
    ""
  );
  const [subscriberLogin, setSubscriberLogin] = useState(
    localStorage.getItem("subscriberLogin") ||
    sessionStorage.getItem("subscriberLogin") ||
    ""
  );
  const [controller, setController] = useState<AbortController | null>(null);
  const [cancelTokens, setCancelTokens] = useState<CancelTokenSource[]>([]);
  const [showGuestLimitError, setShowGuestLimitError] = useState(false);
  const [showUserLimitError, setUserLimitError] = useState(false);
  const [agbChecked, setAgbChecked] = useState(
    localStorage.getItem("agbChecked") ||
    sessionStorage.getItem("agbChecked") ||
    ""
  );
  const [disclaimerChecked, setDisclaimerChecked] = useState(
    localStorage.getItem("disclaimerChecked") ||
    sessionStorage.getItem("disclaimerChecked") ||
    ""
  );
  const [formValidation, setFormValidation] = useState(true);
  const [addImageUrl1, setAddImageUrl1] = useState<string>();
  const [addLink1, setAddLink1] = useState<string>();
  const [addImageUrl2, setAddImageUrl2] = useState<string>();
  const [addLink2, setAddLink2] = useState<string>();
  const [popupTimer, setPopupTimer] = useState(5);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [closing, setClosing] = useState(false);
  const [showErrorDisclaimer, setShowErrorDisclaimer] = useState(false);
  const agbRef = useRef<HTMLInputElement>(null);
  const disclaimerRef = useRef<HTMLInputElement>(null)
  const [paypalLoading, setPayPalLoading] = useState(false);
  const [showSettingModel, setShowSettingModel] = useState(false);
  const [inputSettingCode, setInputSettingCode] = useState(
    localStorage.getItem("settingCode") ||
    sessionStorage.getItem("settingCode") ||
    ""
  );
  const [settingCode, setSettingCode] = useState(
    localStorage.getItem("settingCode") ||
    sessionStorage.getItem("settingCode") ||
    ""
  );
  const [settingCodeVisible, setSettingCodeVisible] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [isMultiline, setIsMultiline] = useState(false);
  const boldStyle: React.CSSProperties = {
    fontWeight: 'bold'
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPopupTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    if (popupTimer === 0) {
      setShowCloseButton(true);
      clearInterval(intervalId);
      setClosing(true)
      setTimeout(() => {
        setShowAddModal(false);
        setClosing(false)
      }, 2000); 
    }

    return () => clearInterval(intervalId);
  }, [popupTimer]);


  const handleClose = () => {
    setShowAddModal(false);
    setClosing(true);
    setTimeout(() => {
      setShowAddModal(false);
      setClosing(false)
    }, 2000); // Fading out takes 1 second
  };


  async function checkAndRefreshToken() {
    setUserToken(await refreshUserToken());
  }

  useEffect(() => {
    ReactGA.initialize(googleAnylaticsToken);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    let intervalId: any;

    if (userToken) {
      checkAndRefreshToken(); // Execute immediately on initial render
      intervalId = setInterval(() => {
        checkAndRefreshToken();
      }, 59 * 60 * 1000); // Every 59 minutes
    }
    return () => clearInterval(intervalId);
  }, []);


  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);

    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (userToken) {
      getConversationIDs(userToken);
    }
  }, [userToken, messageToggle]);

  async function getConversationIDs(userToken: string) {
    setHistoryLoading(true);
    try {
      // const validity = await checkUserValidity();
      let token = userToken;
      // if (validity !== true) {
      //   token = await refreshUserToken();
      // }
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "get_convers_ids",
        {
          token: token,
        },
        {
          cancelToken: source.token,
        }
      );
      const { convers_ids } = response.data;
      setHistory(convers_ids);
      sessionStorage.setItem("history", JSON.stringify(convers_ids));
      const filteredHistory = filterHistory(convers_ids);
      setHistoryFiltered(filteredHistory);
      sessionStorage.setItem(
        "historyFiltered",
        JSON.stringify(filteredHistory)
      );
    } catch (error: any) {
      // toast.error(getErrorMessage(error));
      console.error(error);
    } finally {
      setHistoryLoading(false);
    }
  }

  async function getConvData(id: string) {
    if (convID == id) return;
    cancelAllRequests();
    setChatLoading(true);
    // setProgress(0);
    setConvId(id);
    sessionStorage.setItem("convId", id);

    try {
      let token = userToken;

      const response = await axios.post(BASE_URL + "get_convers_data", {
        token: token,
        convers_id: id,
      });
      const { convers_data } = response.data;
      setMessages(convers_data);
      sessionStorage.setItem("messages", JSON.stringify(convers_data));
    } catch (error: any) {
      toast.error(getErrorMessage(error));
      console.error(error);
    } finally {
      // clearInterval(timer);
      setChatLoading(false);
    }
  }

  function getConvDataForGuest(id: string) {
    if (convID == id) return;
    cancelAllRequests();
    setConvId(id);
    sessionStorage.setItem("convId", id);
    setMessages(messagesList[id]);
    sessionStorage.setItem("messages", JSON.stringify(messagesList[id]));
  }

  async function newChat(token: string) {
    try {
      const createConvData: any = {
        question: chatInput,
      };
      //if (!guestLogin) {
      createConvData.token = token;
      createConvData.code = settingCode;
      //}

      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + `${(guestLogin || userLogin) ? "create_guest_convers" : "create_convers"}`,
        createConvData,
        {
          cancelToken: source.token,
        }
      );
      const { convers_id } = response.data;
      setConvId(convers_id);
      sessionStorage.setItem("convId", convers_id);
      return convers_id;
    } catch (error: any) {
      if (error.response?.data?.message?.includes("Daily limit")) {
        const tempUserLogin = userLogin
        if (tempUserLogin) {
          setUserLimitError(true)
          setChatInput("");
          setIsMultiline(false);
        } else {
          logoutAction();
        }
        setShowAddModal(false)
        setShowModal(true);
        setShowGuestLimitError(true);

        return;
      }
      toast.error(getErrorMessage(error));
      console.error(error);
      setMessageLoading(false);
      setMessageCompleted(true);
    }
  }

  const getUserId = async (idToken: string) => {
    try {
      const getUidData: any = {
        token: idToken
      };

      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "/get_UID",
        getUidData,
        {
          cancelToken: source.token,
        }
      );
      const { UID } = response.data;
      if (UID) {
        setUId(UID)
        sessionStorage.setItem("userId", UID);
      }
    } catch (error: any) {
      console.error("Error getting user id", error);
    }
  }

  async function checkGuestLimit(token?: string) {
    try {
      const createGuestLimitData: any = {
        token: token ?? ""
      };
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "remaining_convers_chances",
        createGuestLimitData,
        // BASE_URL + `remaining_convers_chances`,
        {
          cancelToken: source.token,
        }
      );
      const { remaining_chances, purchased_chances } = response.data;
      setRemainingChance(remaining_chances);
      setPurchasingChance(purchased_chances)
      sessionStorage.setItem("remainingChances", remaining_chances);
      sessionStorage.setItem("purchasedChances", purchased_chances);
      return { remaining_chances, purchased_chances };
    } catch (error: any) {
      console.error("Error fetching remaining chances from the api", error);
      throw error;
    }
  }


  const handlePaypalClick = () => {
    const windowRef = window.open("", "_blank");
    generatePaypalLink(windowRef);
  };

  const generatePaypalLink = async (windowRef: Window | null) => {
    try {
      setPayPalLoading(true);
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post<{ paypal_link: string }>(
        BASE_URL + "generate_paypal_link",
        {},
        {
          cancelToken: source.token,
        }
      );
      const { paypal_link } = response.data;
      if (paypal_link) {
        if (windowRef) {
          windowRef.location.href = paypal_link;
        }
        setPayPalLoading(false);
        setShowModal(false);
      } else {
        setPayPalLoading(false);
      }
    } catch (error: any) {
      setPayPalLoading(false);
      console.error("Error getting paypal link from the API", error);
    }
  };
  async function checkUserIsSubscribed() {
    try {
      const createUserSubscriptionChecking: any = {
        token: userToken
      };
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "check_subscription_status",
        createUserSubscriptionChecking,
        // BASE_URL + `remaining_convers_chances`,
        {
          cancelToken: source.token,
        }
      );
      const { subscribed } = response.data;
      setSubscriberLogin(subscribed.toString())
      setUserLogin((!subscribed).toString())
      localStorage.setItem("subscriberLogin", subscribed.toString());
      localStorage.setItem("userLogin", (!subscribed).toString());
      return subscribed;
    } catch (error: any) {
      console.error("Error fetching subscription status", error);
    }
  }

  async function refreshUserToken() {
    try {
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "refresh_token",
        {
          token: refreshToken,
        },
        {
          cancelToken: source.token,
        }
      );
      const { id_token } = response.data;
      setUserToken(id_token);
      sessionStorage.setItem("userToken", id_token);
      getUserId(id_token);
      return id_token;
    } catch (error) {
      // toast.error(getErrorMessage(error));
      console.error(error);
    }
  }

  async function getLastMessageURL(conversId: string) {
    try {
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + `${guestLogin ? "get_guest_urls" : "get_urls"}`,
        {
          token: userToken,
          convers_id: conversId,
        },
        {
          cancelToken: source.token,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error));
    }
  }

  const sendMessage = async () => {
    if (!chatInput || chatInput.trim().length <= 0) return;
    if (disclaimerChecked === "false" || !disclaimerChecked) {
      setDisclaimerChecked("false")
      setShowErrorDisclaimer(true)
      if (!disclaimerChecked && disclaimerRef.current && disclaimerRef.current.parentElement) {
        disclaimerRef.current.parentElement.classList.add('focused');
      }
      return;
    }
    ReactGA.send({ hitType: "Send button click", page: window.location.pathname });
    window.scrollTo(0, 0);
    setMessageLoading(true); // Set loading to true when button is clicked
    setProgress(0);

    let responseMsg = "";
    let msgObj: Message = {
      user: chatInput,
      assistant: responseMsg,
      timestamp: new Date().toISOString(),
    };
    const newMessages: Messages = { "0": msgObj };
    let index = 1;
    for (const key in messages) {
      newMessages[index.toString()] = messages[key];
      index++;
    }
    setMessages(newMessages);

    if (subscriberLogin != "true") {
      checkGuestLimit(userToken).then((data) => {
        if (data) {
          const { remaining_chances, purchased_chances } = data;
          if (remaining_chances > 0 || remaining_chances === undefined || purchased_chances > 0 || settingCode === "Nei2K32wLMefX5L8oTy7V") {
            showResponseOfMessage(responseMsg, msgObj, newMessages)
          } else {
            delete newMessages["0"];
            setMessages(newMessages);
            if (userLogin === "true") {

              setShowSubscribeModal(true)
            } else {
              setShowGuestLimitError(true);
              setShowModal(true)
            }
            setMessageLoading(false);
            setMessageCompleted(true);
            return
          }
        }
      }).catch((error) => {
        console.error("Error:", error);
        toast.error(getErrorMessage(error));
        setMessageLoading(false);
        setMessageCompleted(true);
      });
    } else {
      showResponseOfMessage(responseMsg, msgObj, newMessages)
    }
  };

  async function showResponseOfMessage(responseMsg: string, msgObj: any, messages: any) {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        const diff = 0.001 * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 0);
    try {
      let token = userToken;
      let msgList = messages;
      let conversId = convID;
      if (!convID || isEmpty(messages) || guestLogin) {
        if (guestLogin) msgList = {};
        conversId = await newChat(token);
      }

      if (!conversId) return;

      const chatData: any = {
        content: chatInput,
        lang: "English",
        convers_id: conversId,
      };
      if (!guestLogin) {
        chatData.token = token;
      }
      chatData.values = selectedLaws
      chatData.code = settingCode
      const charHeaders = {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };


      // msgList = [...messages, msgObj];

      if (chatInput) {
        setChatInput("");
        setIsMultiline(false);
      }

      const abortController = new AbortController();
      setController(abortController);

      const response = await fetch(
        BASE_URL + `${guestLogin ? "guest_chat" : "chat"}`,
        {
          method: "post",
          signal: abortController.signal,
          headers: charHeaders,
          body: JSON.stringify(chatData),
        }
      );
      if (!response.ok || !response.body) {
        if (guestLogin && response.status === 400) {
          toast.error(
            "A guest can ask only 1 question per conversation and a total of 3 questions."
          );
        }
        if (response.status === 429) {
          toast.error("User limited completed")
        }
        throw response.statusText;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const decodedChunk = decoder.decode(value, { stream: true });
        responseMsg += decodedChunk; // update state with new chunk
        msgObj = {
          ...msgObj,
          user: chatInput,
          assistant: responseMsg,
        };
        msgList["0"] = msgObj;
        setMessages(msgList);
        setMessageCompleted(false);
      }
      const urlData = await getLastMessageURL(conversId);
      msgObj.titles = urlData?.titles;
      msgObj.urls = urlData?.urls;
      sessionStorage.setItem("messages", JSON.stringify(msgList));
      setMessageToggle(!messageToggle);
      // history for guest
      updateHistory(conversId, chatInput);
      updateMessagesList(conversId, msgList);
    } catch (error: any) {
      removeUncompletedMessage();
      toast.error(getErrorMessage(error));
      console.error(error);
    } finally {
      clearInterval(timer);
      setMessageLoading(false);
      setMessageCompleted(true);
    }
  }

  function removeUncompletedMessage() {
    const updatedMsgs = Object.fromEntries(
      Object.entries(messages).filter(
        ([key, value]: [string, Message]) => value.assistant ?? false
      )
    );
    setMessages(updatedMsgs);
  }

  function updateHistory(conversId: string, chatInput: string) {
    const updatedHistory = { ...history };
    if (!updatedHistory[conversId]) {
      updatedHistory[conversId] = {
        user: chatInput,
        timestamp: new Date(),
      };
      setHistory(updatedHistory);
      sessionStorage.setItem("history", JSON.stringify(updatedHistory));
      const filteredHistory = filterHistory(updatedHistory);
      setHistoryFiltered(filteredHistory);
      sessionStorage.setItem(
        "historyFiltered",
        JSON.stringify(filteredHistory)
      );
    }
  }

  function updateMessagesList(conversId: string, msgList: Messages) {
    const updateMessagesList = { ...messagesList, [conversId]: msgList };
    setMessagesList(updateMessagesList);
    sessionStorage.setItem("messagesList", JSON.stringify(updateMessagesList));
  }

  const logoutAction = () => {
    localStorage.clear();
    sessionStorage.clear();
    setChatInput("");
    setUserToken("");
    setRefreshToken("");
    setMessages({});
    setMessagesList({});
    setShowModal(true);
    setHistory({});
    setHistoryFiltered({});
    setConvId("");
    setGuestLogin(true);
    setUserLogin("");
    setSubscriberLogin("");
    cancelAllRequests();
    setTermsAccepted(false);
    setShowGuestLimitError(false);
    setUserLimitError(false)
    setUId("")
  };

  const cancelAllRequests = () => {
    if (!isEmpty(cancelTokens)) {
      cancelTokens.forEach((token) => {
        token.cancel("Request canceled by user");
      });
      setCancelTokens([]);
    }

    if (controller) {
      controller.abort();
      setController(null);
    }
  };

  const searchAction = () => {
    if (collapsed) {
      setCollapsed(!collapsed);
    }
  };

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
    const parsedHistory: FilteredHistory = JSON.parse(
      sessionStorage.getItem("historyFiltered") ?? "{}"
    );
    let filteredHistory: FilteredHistory = {};

    if (e.target.value) {
      filteredHistory = {};

      // Iterate over each entry in parsedHistory
      Object.entries(parsedHistory).forEach(([key, value]) => {
        if (value) {
          // Filter the HistoryObj array based on the condition
          const filteredArray = value.filter((historyObj: HistoryObj) => {
            return historyObj.user
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          });

          // Add filtered array to filteredHistory if it's not empty
          if (filteredArray.length > 0) {
            filteredHistory[key] = filteredArray;
          }
        }
      });
    } else {
      // If search value is empty, set filteredHistory to parsedHistory
      filteredHistory = parsedHistory;
    }

    setHistoryFiltered(filteredHistory);
  }

  const toggleSidePanel = () => {
    if (screenWidth > 768) setCollapsed(!collapsed);
    setMobileCollapsed(!mobileCollapsed);
  };

  const toggleMobileSidePanel = () => {
    if (screenWidth <= 768) setMobileCollapsed(!mobileCollapsed);
  };

  const handleAgbCheckChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const isChecked = e.target.checked;
    sessionStorage.setItem("agbChecked", isChecked.toString());
    setAgbChecked(isChecked ? "true" : "false");
  };

  const handleDisclaimerCheckChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const isChecked = e.target.checked;
    sessionStorage.setItem("disclaimerChecked", isChecked.toString());
    setDisclaimerChecked(isChecked ? "true" : "false");
  };



  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setChatInput(value);
    autoResizeTextarea(); // Automatically resize the textarea on change
  };

  // Function to handle enter key
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        e.preventDefault(); // Prevent the default Enter behavior (submitting the form)

        const textarea = e.currentTarget;
        const cursorPosition = textarea.selectionStart;
        const textBeforeCursor = chatInput.slice(0, cursorPosition);
        const textAfterCursor = chatInput.slice(cursorPosition);

        setChatInput(textBeforeCursor + '\n' + textAfterCursor);

        // Maintain the cursor position after the new line
        // setTimeout(() => {
        //   textarea.selectionStart = cursorPosition + 1;
        //   textarea.selectionEnd = cursorPosition + 1;
        // }, 0);
      } else {
        e.preventDefault();
        sendMessage();
      }
    }
  };
  // Auto-resize the textarea height dynamically as content grows
  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset the height to auto
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set the height to match the scrollHeight
      const rows = textareaRef.current.scrollHeight > 40;
      setIsMultiline(rows);
    }
  };

  useEffect(() => {
    autoResizeTextarea(); // Resize on initial load and whenever input changes
  }, [chatInput]);


  const updateSelectedLaws = (law: Law, shouldAdd: boolean) => {
    setSelectedLaws(prevLaws => {
      if (shouldAdd) {
        // Add the law if it's not already included
        return prevLaws.includes(law) ? prevLaws : [...prevLaws, law];
      } else {
        // Remove the law if it's included
        return prevLaws.filter(existingLaw => existingLaw !== law);
      }
    });
  };


  return (
    <div>
      <div className="chatMainFrame">
        <div
          className={`overlay ${!mobileCollapsed ? "active" : ""}`}
          onClick={toggleSidePanel}
        />
        {((!collapsed && screenWidth > 768) || (!mobileCollapsed && screenWidth <= 768)) &&
          <div className={`sidepanel ${!mobileCollapsed ? "active" : ""}`}>
            <Tooltip title={""}>
              <div className="side-toggle" onClick={toggleSidePanel}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="26"
                  viewBox="0 0 15 26"
                  fill="none"
                >
                  <rect width="15" height="26" rx="4" fill="#F3F6F5" />
                  <line x1="5.5" y1="7" x2="5.5" y2="19" stroke="#0D3646" />
                  <line x1="9.5" y1="7" x2="9.5" y2="19" stroke="#0D3646" />
                </svg>
              </div>
            </Tooltip>
            <div className="search-section">
              <div
                className={`d-flex align-items-center searchbox ${(!collapsed || (!mobileCollapsed && screenWidth <= 768)) &&
                  ""
                  }`}
                onClick={searchAction}
              >
                {(!collapsed || (!mobileCollapsed && screenWidth <= 768)) && (
                  <input
                    type="text"
                    placeholder="Search"
                    className="searchinput me-1"
                    onChange={handleSearchChange}
                  />
                )}

                <svg
                  className="clickable"
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                >
                  <path
                    d="M10.3746 19.2873C5.57211 19.2873 1.66211 15.3773 1.66211 10.5748C1.66211 5.77231 5.57211 1.8623 10.3746 1.8623C15.1771 1.8623 19.0871 5.77231 19.0871 10.5748C19.0871 15.3773 15.1771 19.2873 10.3746 19.2873ZM10.3746 3.13731C6.26912 3.13731 2.93711 6.47781 2.93711 10.5748C2.93711 14.6718 6.26912 18.0123 10.3746 18.0123C14.4801 18.0123 17.8121 14.6718 17.8121 10.5748C17.8121 6.47781 14.4801 3.13731 10.3746 3.13731Z"
                    fill="white"
                  />
                  <path
                    d="M19.3002 20.1374C19.1387 20.1374 18.9772 20.0779 18.8497 19.9504L17.1497 18.2504C16.9032 18.0039 16.9032 17.5959 17.1497 17.3494C17.3962 17.1029 17.8042 17.1029 18.0507 17.3494L19.7507 19.0494C19.9972 19.2959 19.9972 19.7039 19.7507 19.9504C19.6232 20.0779 19.4617 20.1374 19.3002 20.1374Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            {(!collapsed || (!mobileCollapsed && screenWidth <= 768)) &&
              !isEmpty(historyFiltered) && (
                <div className="history">
                  {Object.entries(historyFiltered ?? {}).map(
                    ([key, value]: [string, any]) => (
                      <div className="mb-4">
                        <div className="historyHeading">{key}</div>
                        {/* <Tooltip title={value} placement="right"> */}
                        {value.map((item: any) => (
                          <div
                            className={`historyText ${item.key == convID && "active"
                              }`}
                            key={item.key}
                            onClick={() => {
                              toggleMobileSidePanel();
                              guestLogin
                                ? getConvDataForGuest(item.key)
                                : getConvData(item.key);
                            }}
                          >
                            {item.user}
                          </div>
                        ))}

                        {/* </Tooltip> */}
                      </div>
                    )
                  )}
                </div>
              )}
          </div>
        }
        <div className="chatpanel">
          {(collapsed || (mobileCollapsed && screenWidth <= 768)) && (
            <Tooltip title={""}>
              <div className={`side-toggle-2`} onClick={toggleSidePanel}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="26"
                  viewBox="0 0 15 26"
                  fill="none"
                >
                  <rect width="15" height="26" rx="4" fill="none" />  {/* Removed fill color for transparency */}
                  <line x1="5.5" y1="7" x2="5.5" y2="19" stroke="#0D3646" />
                  <line x1="9.5" y1="7" x2="9.5" y2="19" stroke="#0D3646" />
                </svg>
              </div>
            </Tooltip>
          )}
          <div className="top-section">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className={`d-flex ${isMultiline ? 'chatBoxWithText' : 'chatBox'}`}>
                <div className="d-flex">


                  <div className="dropdown">
                    <button
                      className="ds-dropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <HamburgerHorizontalIcon />
                    </button>

                    <ul
                      className="dropdown-menu"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          <label
                            htmlFor="ds1"
                            className="d-flex flex-column"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                className="form-check-input me-2"
                                id="ds1"
                                checked={DS1}
                                onChange={() => {
                                  setDS1(prevDS1 => {
                                    const newDS1 = !prevDS1;
                                    updateSelectedLaws("fed", newDS1);
                                    return newDS1;
                                  });
                                }}
                              />
                              <span>Bundesgesetze & -verordnungen</span>
                            </div>

                            <p className="m-0 ms-4 small-paragraph">
                              (1.792 Gesetze & 2.854 Verordnungen; Stand Aug. 2024)
                            </p>
                          </label>

                        </a>
                      </li>

                    <li>
                      <a className="dropdown-item " href="#">
                        <label
                          htmlFor="ds5"
                          className="d-flex flex-column"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input checkbox-bg me-2"
                              id="ds5"
                              checked={DS5}
                              onChange={() => {
                                setDS5(prevDS5 => {
                                  const newDS5 = !prevDS5;
                                  updateSelectedLaws("eu_ai", newDS5);
                                  return newDS5;
                                });
                              }}
                            />
                            <span className='overlay-dropdown-text'>EU AI Act</span>
                          </div>
                          <p className="m-0 ms-4 overlay-small-paragraph">
                            (VERORDNUNG (EU) 2024/1689 v. 13 Juni 2024 )
                          </p>
                        </label>
                      </a>
                    </li>
                      <li>
                        <a className="dropdown-item " href="#">
                          <label
                            htmlFor="ds2"
                            className="d-flex flex-column"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                className="form-check-input checkbox-bg me-2"
                                id="ds2"
                                checked={DS2}
                                onChange={() => {
                                  setDS2(prevDS2 => {
                                    const newDS2 = !prevDS2;
                                    updateSelectedLaws("bayGe", newDS2);
                                    return newDS2;
                                  });
                                }}
                              />
                              <span>Bayern - Gesetze</span>
                            </div>
                            <p className="m-0 ms-4 small-paragraph">
                              (242 Gesetze; Stand Aug. 2024)
                            </p>
                          </label>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item " href="#">
                          <label
                            htmlFor="ds3"
                            className="d-flex flex-column"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                className="form-check-input me-2"
                                id="ds3"
                                checked={DS3}
                                onChange={() => {
                                  setDS3(prevDS3 => {
                                    const newDS3 = !prevDS3;
                                    updateSelectedLaws("bayVe", newDS3);
                                    return newDS3;
                                  });
                                }}
                              />
                              <span>Bayern - Verordnungen</span>
                            </div>
                            <p className="m-0 ms-4 small-paragraph">
                              (506 Verordnungen; Stand Aug. 2024)
                            </p>
                          </label>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item disabled" href="#">
                          <label
                            htmlFor="ds4"
                            className="d-flex check-group"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              id="ds4"
                              checked={DS4}
                              onChange={() => setDS4(!DS4)}
                            />
                            <span>weitere Datensätze (demnächst)</span>
                          </label>
                        </a>
                      </li>
                      <li className="text-center mt-2">
                        <button className="btn upload-btn disabled">Upload</button>
                      </li>
                    </ul>
                  </div>
                </div>

                <textarea
                  ref={textareaRef}
                  className="chatInput"
                  id="chat"
                  placeholder="Frage hier eingeben"
                  value={chatInput}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  rows={1} /* Start with a single row height */
                />

                <div className="d-flex">

                  <button
                    className={`sendBtn`}
                    onClick={sendMessage}
                    disabled={messageLoading ? true : false}
                    style={{
                      cursor: `${messageLoading ? "not-allowed" : "pointer"}`,
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M13 1L1 3.6087L6.73913 7.26084M13 1L10.3913 13L6.73913 7.26084M13 1L6.73913 7.26084"
                        stroke="white"
                        strokeWidth="1.4757"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="ms-2">Senden</span>
                  </button>
                </div>
              </div>
            </form>
          </div>

          {isEmpty(messages) ? (
            <div className="landing-section">
              <div className="questionsContainer">
                <button
                  className="questionButton"
                  onClick={() => {
                    setChatInput("Mein Flug hatte Verspätung. Bekomme ich mein Geld zurück?");
                  }}
                >
                  Mein Flug hatte Verspätung. Bekomme ich mein Geld zurück?
                </button>
                <button
                  className="questionButton"
                  onClick={() => {
                    setChatInput("Mir wurde gekündigt. Bekomme ich eine Abfindung?");
                  }}
                >
                  Mir wurde gekündigt. Bekomme ich eine Abfindung?
                </button>
                <button
                  className="questionButton"
                  onClick={() => {
                    setChatInput("Ich bin in der 30er Zone zu schnell gefahren. Was passiert nun?");
                  }}
                >
                  Ich bin in der 30er Zone zu schnell gefahren. Was passiert nun?
                </button>
              </div>
              <div className="content-wrapper">
                <div className="line-separator">
                  <hr className="line" />
                  <span className="or-text">Disclaimer/ Haftungsausschluss</span>
                  <hr className="line" />
                </div>
                <div className="landing-para form-group form-check">
                  <input
                    type="checkbox"
                    className={`form-check-input clickable ${showErrorDisclaimer && (disclaimerChecked === "false") ? 'error-disclaimerChecked' : ''}`}
                    id="accept-terms-2"
                    checked={disclaimerChecked === "true"}
                    onChange={handleDisclaimerCheckChange}
                    ref={disclaimerRef}

                  />
                  <label htmlFor="accept-terms-2" className={`clickable ${(showErrorDisclaimer && (disclaimerChecked === "false")) ? 'error-label-disclaimerChecked' : ''}`}>
                    Mir ist bewusst: FragDasGesetz.de ist nur eine maschinengenerierte Zusammenfassung gesetzlicher Bestimmungen und liefert möglicherweise keine vollständige und angemessene Antwort, auch weil jede rechtliche Einordnung davon abhängt, ob alle relevanten Tatsachen offengelegt werden. Die einzelnen Ergebnisse können sich wesentlich ändern, wenn weitere Tatsachen mitgeteilt werden, die vorher nicht eingegeben wurden.  Es ist keine juristische Beratungsleistung durch eine/n Jurist/in und ersetzt diese nicht. Es kommt kein Mandatsverhältnis zustande. Wenden Sie sich in jedem Fall an eine/n fachkundige/n Jurist/-in. Geben Sie keine personenbezogenen Daten ein.
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <div className="message-section">
              {Object.entries(
                Object.fromEntries(
                  Object.entries(messages).filter(
                    ([key, value]) => "assistant" in value
                  )
                ))
                .map(([key, value]: [string, Message], index, array) => (
                  <div key={key}>
                    <div className="user-div">
                      <div className="user">
                        <div
                          className="messageText"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {value.user}
                        </div>
                      </div>
                    </div>
                    <div className="assistant">
                      <div>
                        <span className="me-3 fw-bold d-flex align-items-center" >
                          <FragDasGesetz  aria-label={altTextOfLogo} />
                          <span className="time ms-3">
                            {getFormattedTime(value.timestamp)}
                          </span>
                        </span>
                      </div>
                      <div
                        className="messageText"
                      //   style={{ whiteSpace: "pre-wrap" }}
                      >
                        {value.assistant ? (
                          <ReactMarkdown>{value.assistant}</ReactMarkdown>
                        ) : (
                          <div
                            className="spinner-grow"
                            role="status"
                            style={{ color: "#0d3646" }}
                          />
                        )}
                      </div>
                      {!isEmpty(value.titles) && (
                        <div>
                          <div className="chat-links mt-3">
                            <div className="fw-bold mb-1">Gesetz(e): </div>
                            {value.titles?.map((_, index) => (
                              <div
                                className="d-flex clickable"
                                style={{ gap: "10px" }}
                                key={index}
                              >
                                <div className="lawLinks">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                  >
                                    <path
                                      d="M6.22794 4.77206L9.625 1.375M9.625 1.375V4.28676M9.625 1.375H6.71324M5.01471 1.375H2.45833C1.86002 1.375 1.375 1.86002 1.375 2.45833V8.54167C1.375 9.13997 1.86002 9.625 2.45833 9.625H8.54167C9.13997 9.625 9.625 9.13997 9.625 8.54167V5.98529"
                                      stroke="black"
                                      strokeWidth="0.6"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                                <div style={{ flex: 1 }}>
                                  <a
                                    href={value.urls?.[index] ?? ""}
                                    target="_blank"
                                    className="link-text"
                                  >
                                    {value.titles?.[index] ?? ""}
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="disclaimer">
                            FragDasGesetz.de ist nur eine maschinengenerierte Zusammenfassung gesetzlicher Bestimmungen und stellt keine juristische Beratungsleistung durch einen Mensch dar und ersetzt diese auch nicht. Auch überzeugend erscheinende Antworten sollten ggf. durch eine fachkundige Person (z.B. Rechtsanwalt/-in) überprüft werden.
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        id="loginModal"
        centered
        dialogClassName="signupModal"
      >
        <Modal.Body>
          <div className="modalBody">
            <div className="modalHeading">
              <img src={whatsLegalLogo} className="websiteLogo" />
            </div>
              <div className="loginSection">
                {showGuestLimitError && (
                  <div className="guest-limit-text">
                    <p>
                      <div>FragDasGesetz.de ist kostenlos</div>
                      <div>für 3 Fragen pro Tag.</div>
                      {/* <div>Kommen Sie gerne morgen wieder.</div> */}
                    </p>
                    <p>
                      <div style={boldStyle}>Kaufen Sie 5 weitere Fragen für nur 1€</div>
                    </p>
                    <PaypalButton onClick={handlePaypalClick} />

                  </div>
                )}
              </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddModal}
        onHide={handleClose}
        id="addShowModel"
        centered
        dialogClassName={`feedbackModal add-pop unique-modal ${closing ? 'fade-out' : 'fade-in'}`}
        backdrop="static"
      >
        <Modal.Body>
          <div id="popup-container">
            <div className="timer">{popupTimer}</div>
            {!showCloseButton && <div className="timer">{popupTimer}</div>}
            <div id="popup">
              <a
                rel="sponsored"
                href={addLink1}
                target="_blank"
              >
                <img src={placeHolderAddImage} alt="Advertisement" className="popup-image" loading="lazy" />
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSubscribeModal}
        onHide={() => {
          checkUserIsSubscribed();
          setShowSubscribeModal(false);
        }}
        id="subscriptionModel"
        //backdrop="static"
        centered
        dialogClassName="signupModal"
      >
        <Modal.Body>
          <div className="modalBody">
            <div className="modalHeading">
              <img src={whatsLegalLogo} className="websiteLogo" />
            </div>
            <div className="loginSection">
              <div className="guest-limit-text">
                <p>
                  <div>FragDasGesetz.de is free forever,</div>
                  <div>but just limited to 3 questions a day.</div>
                  <div>Please come back tomorrow</div>
                </p>
                <p>
                  <div>or</div>
                </p>
                <div>Subscribe for just 5€ a month and get 15 questions per day. </div>
                <div>
                  <a
                    href="https://www.whatslegal.ai/free-version"
                    target="_blank"
                  >
                    More info
                  </a>
                </div>
              </div>

              <a className="dropdown-item"
                href={`https://buy.stripe.com/test_bIY4hB6NieRB1qMbII?client_reference_id=${uId}`}
                target="_blank">
                <button
                  type="submit"
                  className="loginBtn"
                  disabled={loginLoading ? true : false}
                  style={{
                    cursor: `${loginLoading ? "not-allowed" : "pointer"}`,
                  }}
                >
                  {!loginLoading ? "Subscribe" : "Subscribing..."}
                </button>
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSettingModel}
        onHide={() => {
          setInputSettingCode(settingCode);
          setShowSettingModel(false);
        }}
        id="settingModal"
        // backdrop="static"
        centered
        dialogClassName="feedbackModal"
      >
        <Modal.Body>
          <div className="d-flex align-items-center mb-3">
            <input
              type={settingCodeVisible ? "text" : "password"}
              className="form-control loginTextbox"
              id="settingCode"
              placeholder="Enter Code"
              value={inputSettingCode}
              onChange={(e) => {
                setInputSettingCode(e.target.value);
              }} />
            {settingCodeVisible ? (
              <VisibilityOffOutlinedIcon
                className="eyeIcon clickable"
                onClick={() => setSettingCodeVisible(!settingCodeVisible)} />
            ) : (
              <VisibilityOutlinedIcon
                className="eyeIcon clickable"
                onClick={() => setSettingCodeVisible(!settingCodeVisible)} />
            )}
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn closeProfileBtn"
              onClick={() => {
                setSettingCode(inputSettingCode);
                localStorage.setItem("settingCode", inputSettingCode);
                setShowSettingModel(false);
              }}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChatBot;

