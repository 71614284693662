import React, { useState } from 'react';
import Header from '../components/Header';
import ChatBot from '../components/ChatBot';
import WhyWhatsLegal from '../components/WhyWhatsLegal';
import Footer2 from '../components/Footer';
import Footer2Mobile from '../components/Footer-Mobile';
import useWindowSize from '../hooks/useWindowSize';
import { seoData, whyWhatslegalContent } from '../data/free-version';
import SEO from '../components/Seo';

const WhatsLegalChat: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  return (
    <>
      <SEO
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
        url={seoData.url}
      />
      <Header />
      {/* <Banner /> */}
      <ChatBot />
      <WhyWhatsLegal content={whyWhatslegalContent}  titleForIframe={seoData.titleForIframWhyWhatslegal}  />
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </>
  );
};

export default WhatsLegalChat;
