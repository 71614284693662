export const title = "KI Chatbot<br />für Gesetze";
export const subTitle = "Kostenlos. Verständlich. Für alle."
export const buttonText = "Frage hier stellen"


export const secondBannerText = "Chatten Sie einfach mit <br /> ca. 5.000 aktuellen Gesetzen*"
export const secondBannerSubText = "*deutsche Bundesgesetze und Verordnungen - Stand Jan. 2025"

export const features = (`# Features
  * Macht Gesetze für alle Bürger*innen leicht zugänglich und verständlich
  * Bietet vertrauensvolle und anonyme Informationsquelle (keine Cookies, kein Tracking, keine Anmeldung, keine persönlichen Daten)
  * Unterstützt Laien und Profis beim Recherchieren in Gesetzen
  * Ist kostenlos bis ca. 10 Fragen pro Tag
        `);


//SEO DATA

export const seoData = {
  title: "Frag das Gesetz, der KI Chatbot für deutsche Gesetze",
  description: "Entdecken Sie den KI-gestützten Chatbot für deutsche Bundesgesetze. Schnelle, präzise Antworten zu rechtlichen Fragen – einfach, verständlich und rund um die Uhr verfügbar",
  keywords: "Bundesgesetze Chatbot, Bundesrecht online versehen, deutsche Bundesgesetze, Gesetzesinformationen, rechtliche Recherche",
  url: "https://www.frag-das-gesetz.de/",
  altTextBackground: "Frau liest Bundesgesetze mit Frag das Gesetz",
  titleForIframeFeatures: "Video Warum frag das Gesetz"
};
