// src/pages/Home.tsx
import React, { useState } from 'react';
import Header from '../components/Header';
import SecondHeader from '../components/SecondHeader';
import Footer2 from '../components/Footer';
import Footer2Mobile from '../components/Footer-Mobile';
import useWindowSize from '../hooks/useWindowSize';
import Hero from '../components/HeroSection';
import OverlayChatbot from '../components/OverlayChatbot';
import FloatingButton from '../components/FloatingButton';
import floatingChatIcon from "../assets/extra-images/smiley-comment-alt.png";

import whiteLabelMobile from '../assets/white-label/anwalt-nutzt-white-label-von-frag-das-gesetz-fuer-kundenservice-und-lead-generierung-mobile.jpeg';
import whiteLabelImage from '../assets/white-label/anwalt-nutzt-white-label-von-frag-das-gesetz-fuer-kundenservice-und-lead-generierung-desktop.jpeg';
import embeddedImage from '../assets/white-label/ki-chatbot-white-label-embedded-version.png';
import overlayImage from '../assets/white-label/ki-chatbot-white-label-overlay-version.png';
import TextVideo from '../components/TextVideo';
import WhiteLabelsOfferings from '../components/WhiteLabelsOffering';

import CaptainFrankLogo from '../assets/logos/captain-frank/CaptainFrank.png';
import { buttonText, featuresButtonText, newWiteLabelContentFeatures, secondBannerSubText, secondBannerText, seoData, subTitle, title } from '../data/white-label-page';
import SEO from '../components/Seo';

const NewWhiteLabel: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  const openChatbot = () => setIsChatbotOpen(true);
  const closeChatbot = () => setIsChatbotOpen(false);
  const captainFrankRedirectLink = "https://www.captain-frank.com/de/cflegalai";

  return (
    <>
      <SEO
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
        url={seoData.url}
      />
      <Header />
      <Hero normalImage={whiteLabelImage}
        mobileImage={whiteLabelMobile}
        heading={<div dangerouslySetInnerHTML={{ __html: title }} />}
        subHeading={<div dangerouslySetInnerHTML={{ __html: subTitle }} />}
        buttonHeading={buttonText}
        link={captainFrankRedirectLink}
        altText={seoData.altTextBackground} />
      <SecondHeader
        title={<div dangerouslySetInnerHTML={{ __html: secondBannerText }} />}
        secondTitle={<div dangerouslySetInnerHTML={{ __html: secondBannerSubText }} />}
        backgroundColor="#044557"
      />
      <TextVideo content={newWiteLabelContentFeatures} showButton={"true"} videoLink='https://share.synthesia.io/embeds/videos/cc7f6069-58a4-4748-a24f-77f788cfc8f3' buttonText={featuresButtonText} iframeTitle={seoData.iFrameFeaturesTitle} />

      <WhiteLabelsOfferings embeddedImage={embeddedImage} overlayImage={overlayImage} kiChatbotWhiteLabelEmbeddedAltText={seoData.kiChatbotWhiteLabelEmbeddedAltText}
        kiChatbotWhiteLabelOverlayAltText={seoData.kiChatbotWhiteLabelOverlayAltText} />

      <FloatingButton
        onClick={openChatbot}
        icon={<img src={floatingChatIcon} alt="Chat Icon" />}
      />
      <OverlayChatbot isOpen={isChatbotOpen} onClose={closeChatbot} />

      <SecondHeader
        title={
          <>
            Kunden/ Referenzen:&nbsp;
            <a
              href={captainFrankRedirectLink}
              target="_blank"
              rel="noopener noreferrer">
              <img
                src={CaptainFrankLogo}
                alt={seoData.altTextCaptainFrankLogo}
              />
            </a>
          </>
        }
        backgroundColor="#044557"
      />
      {/* <Banner /> */}
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </>
  );
};

export default NewWhiteLabel;
