import React, { useState } from 'react';
import './css/TextComponent.css';
import AboutWithTick from './AboutWithTick';

interface TextProps {
  title?: string
  content: string;
}


const TextComponent: React.FC<TextProps> = ({ title, content }) => {
  return (
    <div >
      <div className="text-container" >
          <AboutWithTick paragraphs={[content]} />
      </div>
    </div>
  );
};

export default TextComponent;
