import { FAQ } from "../helpers/types";

export const faqData: FAQ[] = [
    {
        question: "Welche Gesetze sind in FragDasGesetz.de enthalten?",
        answer:
            "FragDasGesetz.de enthält derzeit alle Bundesgesetze und Verordnungen (Stand August 2024), wie sie auf der offiziellen Seite gesetze-im-internet.de zur Verfügung gestellt werden. FragDasGesetz.de enthält ebenso die bayerischen Gesetze und Verordnungen (Stand August 2024), wie sie auf der offiziellen Seite zur Verfügung gestellt werden. Wir arbeiten kontinuierlich an der Erweiterung und Aktualisierung der Inhalte.",
    },
    {
        question: "Technologie von FragDasGesetz.de",
        answer:
            "FragDasGesetz.de nutzt neueste Technologien aus dem Bereich der generativen KI und kombiniert diese mit hybriden Suchalgorithmen in einer komplexen RAG (retrieval augmented generation) Pipeline bzw. Plattform.\n\nWir arbeiten kontinuierlich und mit Leidenschaft daran, die faszinierende Technologie der generativen KI ständig zu verbessern, um den Nutzer*innen ein immer besseres Erlebnis und Ergebnis zu liefern.",
    },
    {
        question: 'Unterschied zwischen "suchen" und "prompten"',
        answer:
            "Der Unterschied zwischen herkömmlichen Suchen und Prompten liegt hauptsächlich in der Art der Interaktion und Informationsbereitstellung. Bei einer herkömmlichen Suche gibt der Nutzer einen Suchbegriff in eine Suchmaschine ein, die dann das Internet nach relevanten Inhalten durchsucht und eine Liste von Links zu Webseiten präsentiert. Diese Methode ist nutzerzentriert, da der Nutzer selbst durch die Links navigieren und die relevanten Informationen extrahieren muss. Die Ergebnisse sind oft nach Relevanz, Popularität und Aktualität sortiert und basieren auf den eingegebenen Schlüsselwörtern.\n\nIm Gegensatz dazu bezieht sich Prompten auf die Interaktion mit KI-Modellen wie ChatGPT. Der Nutzer gibt eine Eingabe (Prompt), und die KI generiert eine direkte, kontextbasierte Antwort oder Handlung. Diese Methode bietet eine konversationelle Interaktion, bei der der Nutzer sofortige, ausführliche Antworten erhält, die auf dem Wissen der KI basieren. Im Gegensatz zur herkömmlichen Suche muss der Nutzer nicht durch eine Liste von Links navigieren, sondern bekommt die benötigten Informationen direkt präsentiert. Prompten eignet sich besonders für Anfragen, die eine umfassende, direkte Antwort erfordern.",
    },
    {
        question: "Länge eines Prompts",
        answer:
            "Um bestmögliche Ergebnisse zu erhalten, ist die Länge des Prompts wichtig, um genügend Informationen zu geben, damit FragDasGesetz.de die Anfrage besser versteht und angemessen darauf antworten kann. Hier sind einige Empfehlungen zur Länge eines Prompts:\n\nKurze Prompts: Wenn du eine präzise und einfache Anfrage hast, reichen oft schon kurze Prompts aus. Ein bis zwei Sätze könnten genug sein, um deine Frage oder Anfrage klarzustellen.\nMittlere Prompts: Für komplexere Fragen oder Anfragen, die zusätzlichen Kontext erfordern, könntest du einen mittleren Prompt verwenden. Dies könnte ein Absatz von zwei bis drei Sätzen sein, der die Frage umreißt und relevante Hintergrundinformationen bereitstellt.\nLängere Prompts: Wenn du sehr spezifische Anforderungen hast oder viele Details bereitstellen möchtest, kann ein längerer Prompt notwendig sein. Dies könnte mehrere Absätze oder sogar ein kurzer Artikel sein, der den Kontext ausführlich beschreibt.\nGenerell gilt: Stelle sicher, dass dein Prompt ausreichend Informationen enthält, damit FragDasGesetz.de deine Anfrage gut verstehen und angemessen darauf reagieren kann. Vermeide es jedoch, den Prompt unnötig zu verlängern, da zu viel Kontext die Fähigkeit von FragDasGesetz.de beeinträchtigen kann, relevante Informationen zu extrahieren und die beste Antwort zu generieren.",
    },
    {
        question: "Inhalt eines Prompts",
        answer:
            "Um die besten Ergebnisse mit FragDasGesetz.de zu erzielen, ist eine klare und präzise Formulierung des Prompts entscheidend. Hier ist eine kurze Anleitung, wie man inhaltlich optimal prompt:\n\nPräzise Fragestellung: Frage oder Anfrage klar und präzise. Vermeide vage oder mehrdeutige Formulierungen, um Missverständnisse zu vermeiden. Je klarer deine Frage, desto besser die Antwort.\nVermeide Doppeldeutigkeiten: Versuche, deine Frage so zu formulieren, dass sie nicht mehrere Interpretationen zulässt. Verwende klare und eindeutige Sprache, um Missverständnisse zu minimieren.\nIterationen durchführen: Manchmal kann es erforderlich sein, mehrere Iterationen des Prompts durchzuführen, um die gewünschten Ergebnisse zu erzielen. Sei geduldig und experimentiere mit verschiedenen Formulierungen, bis du die beste Antwort erhältst.",
    },
    {
        question: "Gibt es auch eine Pro Version?",
        answer:
            "Unser primäres Ziel war, für alle Bürger*innen einen einfachen, kostenlosen und anonymen Zugang zum Gesetz zu schaffen und dieses für alle verständlich zu machen. Wir hoffen, das mit der Free Version geschafft zu haben. Allerdings arbeiten wir auch an einer Pro Version sowie einer Enterprise Version.",
    },
    {
        question: 'Was bedeutet "WhiteLabel"?',
        answer:
            "Wir bieten unsere Technologie ohne Logo an, damit Kunden ihr eigenes Logo und Design und unsere Technologie in ihrer Webseite einbinden können und mehr Leads zu generieren. Mehr dazu finden Sie <a href='/whitelabel' target='_blank'>hier</a>.",
    },
    {
        question: "Kann man Werbung schalten?",
        answer:
            "Ja. Wir refinanzieren unser kostenloses Angebot durch Werbung und Whitelabel.",
    },
];

//SEO DATA

export const seoData = {
    title: "FragDasGesetz",
    description: "Nutzen Sie den kostenlosen KI Chatbot für Gesetze, der über 5.000 aktuelle deutsche Bundesgesetze und Verordnungen leicht zugänglich und verständlich macht.",
    keywords: "KI Chatbot, Gesetze, deutsche Bundesgesetze, kostenlos, verständlich, Gesetzesinformationen, anonymer Zugang, rechtliche Recherche",
    url: "https://www.whatslegal.com/",
  };